import { create } from "zustand"
import { immer } from "zustand/middleware/immer"
import { uniq, cloneDeep } from "lodash"

import { vehicleSpeedUnitTypes, vehicleTypes } from "utils/constants"

import type {
    DbOptimizationSettings,
    DbStartEndOption,
    DbWorkerScheduleType,
    DbWorkerScheduleField,
    DbVehicleType,
    DbVehicleSpeedUnit,
    DbOptMeasure,
    DbAdRunParamsLatest,
} from "../../../types/lib/db"

type OptType = "ro" | "ad"

type OptActions = {
    actions: {
        updateForDate: (service: OptType, forDate: string) => void,
        updateTimezone: (service: OptType, timezone: string) => void,
        updateMeasure: (service: OptType, measure: DbOptMeasure) => void,
        updateSimpleQuantityManagement: (service: OptType, enabled: boolean) => void,
        updateOptimizationSettingsNumerical: (
            service: OptType,
            setting: NumericalOptimizationSetting,
            val: string
        ) => void,
        updateOptimizationSettingsWorkerSchedule: (
            service: OptType,
            scheduleType: DbWorkerScheduleType,
            field: DbWorkerScheduleField,
            val: string
        ) => void,
        updateOptimizationSettingsVehicleSpeed: (
            service: OptType,
            vehicleType: DbVehicleType,
            field: "unit" | "value",
            val: string
        ) => void
    }
}

type OptState = {
    ro: {
        state: RoState,
        actions: RoActions
    }
    ad: {
        state: AdState,
        actions: AdActions
    },
}

export const roEmptyState: RoState = {
    for_date: "",
    start_at: "worker_locations",
    end_at: "worker_locations",
    tasks_selected: [],
    timezone: "",
    simple_quantity_management: false,
    workers_selected_per_team: {},
    workers_selected_all: [],
    optimization_settings: {
        measure: "default",
        route_max_tasks: 10,
        task_service_time: 1,
        task_max_delay: 1,
        task_quantity: 1,
        solver_duration: 5,
        worker_capacity: 5,
        worker_schedule: {
            shift_start: {
                hour: 9,
                minute: 0,
                modifier: "AM"
            },
            shift_end: {
                hour: 8,
                minute: 0,
                modifier: "PM"
            }
        },
        worker_vehicle_speed: {
            car: {
                value: 30,
                unit: "mph"
            },
            truck: {
                value: 25,
                unit: "mph"
            },
            motorcycle: {
                value: 30,
                unit: "mph"
            },
            bicycle: {
                value: 15,
                unit: "mph"
            },
            walking: {
                value: 5,
                unit: "mph"
            }
        }

    },
    initialize_state: true,
    fetch: {
        teams: false,
        tasks: false,
        workers: false,
        hubs: false
    }
}

export const adEmptyState: AdState = {
    team_id: "",
    for_date: "",
    /* start_at: "worker_locations", */
    end_at: "worker_locations",
    timezone: "",
    simple_quantity_management: false,
    optimization_settings: {
        measure: "default",
        route_max_tasks: 10,
        task_service_time: 1,
        task_max_delay: 1,
        task_quantity: 1,
        solver_duration: 5,
        worker_capacity: 5,
        worker_schedule: {
            shift_start: {
                hour: 9,
                minute: 0,
                modifier: "AM"
            },
            shift_end: {
                hour: 8,
                minute: 0,
                modifier: "PM"
            }
        },
        worker_vehicle_speed: {
            car: {
                value: 30,
                unit: "mph"
            },
            truck: {
                value: 25,
                unit: "mph"
            },
            motorcycle: {
                value: 30,
                unit: "mph"
            },
            bicycle: {
                value: 15,
                unit: "mph"
            },
            walking: {
                value: 5,
                unit: "mph"
            }
        }
    },
    run_params_latest: {
        omit_flagged_tasks: true,
        offline_workers_enabled: false,
        global_unassigned_enabled: false,
        allow_unassigned: true,
        worker_backlog_lock: false,
        worker_backlog_order_lock: false,
        start_at: "worker_locations",
        end_at: "worker_locations"
    },
    initialize_state: true
}

export const useOptStore = create(
    immer<OptState & OptActions>((set, get) => ({
        ro: {
            state: { ...roEmptyState },
            actions: {
                initRoState: (roState: RoState) => {
                    set(state => {
                        state.ro.state = roState
                    })
                },
                updateWorkersSelected: (params: {
                    team: string,
                    teamChecked?: boolean,
                    worker?: string,
                    workerChecked?: boolean
                }) => {
                    const {
                        team,
                        teamChecked,
                        worker,
                        workerChecked
                    } = params
                    const ref = cloneDeep(get().ro.state.workers_selected_per_team)
                    const teamMatched = ref[team]
                    if (!teamMatched) {
                        return
                    }
                    if (!worker) {
                        const updatedWorkersSelected = []
                        if (teamChecked) {
                            updatedWorkersSelected.push(...teamMatched.workers_in_team)
                        }
                        teamMatched.workers_selected = updatedWorkersSelected
                    } else {
                        const updatedWorkersSelected = workerChecked
                            ? [...teamMatched.workers_selected, worker]
                            : teamMatched.workers_selected.filter(w => w !== worker)
                        teamMatched.workers_selected = updatedWorkersSelected
                    }

                    const allWorkersSelected = Object.values(ref).reduce((acc, el) => {
                        return [...acc, ...el.workers_selected]
                    }, [] as string[])

                    set(state => {
                        state.ro.state.workers_selected_per_team = ref
                        state.ro.state.workers_selected_all = uniq(allWorkersSelected)
                    })
                },
                updateRouteStartEnd: (key: "start_at" | "end_at", value: string) => {
                    set(state => {
                        state.ro.state[key] = value
                    })

                },
                triggerFetches: (fetches: Partial<RoActiveFetches>) => {
                    set(state => {
                        state.ro.state.fetch = {
                            ...state.ro.state.fetch,
                            ...fetches
                        }
                    })
                },
                reset: () => {
                    set(state => {
                        state.ro.state.initialize_state = true
                    })
                }
            }
        },
        ad: {
            state: { ...adEmptyState },
            actions: {
                initAdState: (adState: AdState) => {
                    set(state => {
                        state.ad.state = adState
                    })
                },
                updateRunParamsLatestBool: (field: RunParamsLatestBoolField, val: boolean) => {
                    set(state => {
                        state.ad.state.run_params_latest[field] = val
                    })
                },
                updateRunParamsStartEndAt: (field: RunParamsStartEndAtField, val: DbStartEndOption) => {
                    set(state => {
                        state.ad.state.run_params_latest[field] = val
                    })
                }
            }
        },
        actions: {
            updateForDate: (service: OptType, forDate: string) => {
                set(state => {
                    state[service].state.for_date = forDate
                })
            },
            updateTimezone: (service: OptType, timezone: string) => {
                set(state => {
                    state[service].state.timezone = timezone
                })
            },
            updateMeasure: (service: OptType, measure: DbOptMeasure) => {
                set(state => {
                    state[service].state.optimization_settings.measure = measure
                })
            },
            updateSimpleQuantityManagement: (service: OptType, enabled: boolean) => {
                set(state => {
                    state[service].state.simple_quantity_management = enabled
                })
            },
            updateOptimizationSettingsNumerical: (
                service: OptType,
                setting: NumericalOptimizationSetting,
                val: string
            ) => {
                if (val === "") {
                    set(state => {
                        state[service].state.optimization_settings[setting] = "" as any
                    })
                    return
                }
                const intVal = parseInt(val)
                if (isNaN(intVal) || typeof intVal !== "number") {
                    return
                }
                set(state => {
                    state[service].state.optimization_settings[setting] = intVal
                })
            },
            updateOptimizationSettingsWorkerSchedule: (
                service: OptType,
                scheduleType: DbWorkerScheduleType,
                field: DbWorkerScheduleField,
                val: string
            ) => {
                const intVal = parseInt(val)
                switch (field) {
                    case "hour": {
                        set(state => {
                            state[service].state.optimization_settings.worker_schedule[scheduleType].hour = intVal
                        })
                        return
                    }
                    case "minute": {
                        set(state => {
                            state[service].state.optimization_settings.worker_schedule[scheduleType].minute = intVal
                        })
                        return
                    }
                    case "modifier": {
                        set(state => {
                            state[service].state.optimization_settings.worker_schedule[scheduleType].modifier = val as "AM" | "PM"
                        })
                        return
                    }
                    default: {
                        return
                    }
                }
            },
            updateOptimizationSettingsVehicleSpeed: (
                service: OptType,
                vehicleType: DbVehicleType,
                field: "unit" | "value",
                val: string
            ) => {
                if (!vehicleTypes.includes(vehicleType)) {
                    return
                }
                if (field === "value" && val === "") {
                    set(state => {
                        state[service].state.optimization_settings.worker_vehicle_speed[vehicleType].value = "" as any
                    })
                    return
                }
                switch (field) {
                    case "value": {
                        const parsedSpeed = parseInt(val)
                        if (isNaN(parsedSpeed) || typeof parsedSpeed !== "number") {
                            return
                        }
                        set(state => {
                            state[service].state.optimization_settings.worker_vehicle_speed[vehicleType].value = parsedSpeed
                        })
                        return
                    }
                    case "unit": {
                        if (!vehicleSpeedUnitTypes.includes(val as DbVehicleSpeedUnit)) {
                            return
                        }
                        set(state => {
                            state[service].state.optimization_settings.worker_vehicle_speed[vehicleType].unit = val as DbVehicleSpeedUnit
                        })
                        return
                    }
                    default: {
                        return
                    }
                }
            }
        }
    }))
)

type RoActiveFetches = {
    teams: boolean,
    tasks: boolean,
    hubs: boolean,
    workers: boolean,
}

type RoActions = {
    updateWorkersSelected: (params: {
        team: string,
        teamChecked?: boolean,
        worker?: string,
        workerChecked?: boolean
    }) => void,
    triggerFetches: (fetches: Partial<RoActiveFetches>) => void,
    initRoState: (state: RoState) => void,
    updateRouteStartEnd: (
        key: "start_at" | "end_at",
        value: string
    ) => void,
    reset: () => void
}

type NumericalOptimizationSetting = "route_max_tasks"
    | "task_service_time"
    | "task_max_delay"
    | "task_quantity"
    | "solver_duration"
    | "worker_capacity"

type RoState = {
    for_date: string,
    start_at: string,
    end_at: string,
    tasks_selected: string[],
    timezone: string,
    simple_quantity_management: boolean,
    workers_selected_per_team: {
        [teamId: string]: {
            workers_selected: string[],
            workers_in_team: string[]
        }
    },
    workers_selected_all: string[],
    optimization_settings: DbOptimizationSettings,
    initialize_state: boolean,
    fetch: {
        teams: boolean,
        tasks: boolean,
        workers: boolean,
        hubs: boolean

    }
}

type RunParamsLatestBoolField = "allow_unassigned"
    | "global_unassigned_enabled"
    | "offline_workers_enabled"
    | "omit_flagged_tasks"
    | "worker_backlog_lock"
    | "worker_backlog_order_lock"

type RunParamsStartEndAtField = "start_at" | "end_at"

type AdActions = {
    updateRunParamsLatestBool: (field: RunParamsLatestBoolField, val: boolean) => void,
    updateRunParamsStartEndAt: (field: RunParamsStartEndAtField, val: DbStartEndOption) => void,
    initAdState: (adState: AdState) => void
}

type AdState = {
    for_date: string,
    end_at: DbStartEndOption,
    timezone: string,
    simple_quantity_management: boolean,
    team_id: string,
    optimization_settings: DbOptimizationSettings,
    run_params_latest: DbAdRunParamsLatest,
    initialize_state: boolean
}
