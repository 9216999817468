import config from "config"
// types
import type { ParamDetails } from "shared/components/ApiDoc"

export const apiDocExampleReqBody = {
    tasks: ["11z1BbsQUZFHD1XAd~emDDeK", "qNMz6CKwQ*26FOslywsiQxhY"],
    workers: ["h*wSb*apKlDkUFnuLTtjPke7", "1LjhGUWdxFbvdsTAAXs0TFos"]
}

export const apiMethod = "POST"

export const apiUrl = config.serverURL + "/ro/run"

export const apiDocReqParams: ParamDetails[] = [
    {
        name: "tasks",
        required: true,
        type: "[ ]string",
        default: null,
        description: "IDs of tasks to optimize"
    },
    {
        name: "workers",
        required: true,
        type: "[ ]string",
        default: null,
        description: "IDs of workers to optimize tasks across"
    },
    {
        name: "for_date",
        required: false,
        type: "string",
        default: `current date "yyyy-mm-dd"`,
        description: `Date to optimize for (e.g. "2022-10-31")`
    },
    {
        name: "end_at",
        required: false,
        type: "string",
        default: `"worker_locations" (can be one of "worker_locations", "worker_addresses", or a specific team hub id)`,
        description: "Defines where workes will end their routes."
    },
    {
        name: "start_at",
        required: false,
        type: "string",
        default: `"worker_locations" (can be one of "worker_locations", "worker_addresses", or a specific team hub id)`,
        description: "Defines where workes will start their routes."
    },
    {
        name: "timezone",
        required: false,
        type: "string",
        default: "organization timezone",
        description: "Timezone to be used for timezone dependent data in optimization (such as worker schedules)"
    },
    {
        name: "route_max_tasks",
        required: false,
        type: "integer",
        default: "Maximum number of tasks per route set on account",
        description: "Maximum number of tasks that can be assigned to any one driver’s route"
    },
    {
        name: "task_service_time",
        required: false,
        type: "integer",
        default: "Service time per task (in minutes) set on account",
        description: "Duration of time after worker arrives at a destination but before worker leaves a destination. Applied as a default for tasks that do not have service time set / service time is zero"
    },
    {
        name: "worker_capacity",
        required: false,
        type: "integer",
        default: "Default driver vehicle capacity set on account",
        description: "Max capacity for workers. Applied as default for all workers that do not have a capacity set / capacity is zero"
    },
    {
        name: "worker_schedule",
        required: false,
        type: "[  ]integer",
        default: "Default driver schedule set on account",
        description: "Driver schedule for workers as array of length 2 with shift start as first element and shift end as second element (both in represented as unix timestamp in  milliseconds). Applied as default for all workers that do not have a schedule set for the date being optimization for (aka 'for_date'). If 'worker_schedule' is specified any value specified for 'timezone' becomes irrelevant."
    },
    {
        name: "solver_duration",
        required: false,
        type: "integer",
        default: "Solver duration set on account",
        description: "Maximum amount of time, in seconds, the routing engine can spend optimizing. Increasing the solver duration may or may not improve results."
    }
]
