import { useState, useEffect } from "react"
import { DateTime } from "luxon"
import Slider from "rc-slider"
import 'rc-slider/assets/index.css'

// stores
import { useUserStore } from "stores/global"
import { useOptStore } from "stores/opt"

import "./Sidebar.css"

// types
import type {
    ChangeEvent
} from "react"
import type {
    MouseEvent
} from "react"
import type {
    OnfleetTask
} from "@onfleet/node-onfleet/Resources/Tasks"
import type {
    OnfleetWorker
} from "@onfleet/node-onfleet/Resources/Workers"
import type {
    OnfAllTeamsResponseData,
    OnfAllWorkersResponseData,
    OnfTasksListResponseData,
} from "@/../../../types/services/onfleet"

type SidebarProps = {
    loadingTeams: boolean,
    loadingTasks: boolean,
    loadingWorkers: boolean,
    teams: OnfAllTeamsResponseData | null,
    tasks: OnfTasksListResponseData,
    workers: OnfAllWorkersResponseData,
    taskIdsSelected: string[],
    handleTaskSelectionToggle: (
        taskId: string,
        e: MouseEvent<HTMLDivElement>,
        type: "team" | "global_unassigned",
        teamId?: string
    ) => void,
    globalUnassignedTaskIds: string[],
    taskDateWindow: string,
    setTaskDateWindow: (date: string) => void,
    setTaskIdsSelected: (ids: string[]) => void
}

export default function Sidebar({
    loadingTasks,
    loadingTeams,
    loadingWorkers,
    teams,
    tasks,
    workers,
    taskIdsSelected,
    handleTaskSelectionToggle,
    globalUnassignedTaskIds,
    taskDateWindow,
    setTaskDateWindow,
    setTaskIdsSelected
}: SidebarProps) {
    const user = useUserStore(state => state.user)
    const roState = useOptStore(state => state.ro.state)
    const roActions = useOptStore(state => state.ro.actions)
    const timezone = roState.timezone

    const [activeTeamMenus, setActiveTeamMenus] = useState(new Set())
    const [activeGlobalUnassignedMenu, setActiveGlobalUnassignedMenu] = useState(false)

    const [hourBounds, setHourBounds] = useState<[number, number]>([0, 24])

    const initMillisBounds = (dateSelected: string, timezone?: string): [number, number] => {
        const date = timezone
            ? DateTime.fromFormat(dateSelected, "y-LL-dd", { zone: timezone })
            : DateTime.fromFormat(dateSelected, "y-LL-dd")

        return [
            date.startOf("day").toMillis(),
            date.startOf("day").plus({ days: 1 }).toMillis()
        ]
    }

    const initDateWindow = (timezone?: string) => {
        const date = timezone
            ? DateTime.now().setZone(timezone)
            : DateTime.now()
        return date.toFormat("y-LL-dd")
    }

    const initialDateWindow = taskDateWindow ?? initDateWindow(user?.timezone)

    const [millisBounds, setMillisBounds] = useState<[number, number]>(initMillisBounds(initialDateWindow, user?.timezone))

    const [taskFilter, setTaskFilter] = useState<"window_filtered" | "none">("window_filtered")

    useEffect(() => {
        if (taskDateWindow) {
            setMillisBounds(initMillisBounds(taskDateWindow, user?.timezone))
        }
    }, [taskDateWindow])

    useEffect(() => {
        if (taskDateWindow) {
            const millisBounds = getMillisTimeBounds(
                taskDateWindow,
                hourBounds,
                timezone
            )
            setMillisBounds(millisBounds)
        }
    }, [hourBounds[0], hourBounds[1]])

    useEffect(() => {
        if (taskDateWindow) {
            setHourBounds([0, 24])
        }
    }, [taskDateWindow])

    const handleTeamMenuActiveToggle = (teamId: string) => {
        const newSet = new Set(activeTeamMenus)
        if (newSet.has(teamId)) {
            newSet.delete(teamId)
        } else {
            newSet.add(teamId)
        }
        setActiveTeamMenus(newSet)
    }

    const handleTeamTasksSelectAllToggle = (teamId: string | null = null) => {
        let refTasks = []
        if (teamId === null) {
            const matchedRefTasks: Set<string> = new Set()
            for (const taskData of Object.values(tasks)) {
                const isOrgContainer = taskData.container?.type === "ORGANIZATION"
                const inDateRange = taskInBounds(taskData)
                if (isOrgContainer && inDateRange) {
                    matchedRefTasks.add(taskData.id)
                }
            }
            refTasks = [...matchedRefTasks]
        } else {
            const matchedTeam = teams?.find(t => t.id === teamId)
            if (!matchedTeam) {
                return
            }
            const matchedTeamTypelessRef = matchedTeam as any
            const matchedRefTasks: Set<string> = new Set()

            for (const taskData of Object.values(tasks)) {
                const inDateRange = taskInBounds(taskData)
                if (matchedTeamTypelessRef.tasks.includes(taskData.id) && inDateRange) {
                    matchedRefTasks.add(taskData.id)
                }
            }
            refTasks = [...matchedRefTasks]
        }
        const allTeamTasksSelected = refTasks.every(taskId => taskIdsSelected.includes(taskId))
        const taskIdSet = new Set(taskIdsSelected)
        if (allTeamTasksSelected) {
            for (const taskId of refTasks) {
                taskIdSet.delete(taskId)
            }
        } else {
            for (const taskId of refTasks) {
                taskIdSet.add(taskId)
            }
        }
        setTaskIdsSelected([...taskIdSet])
    }



    const formatSliderTimeWindow = () => {
        if (!taskDateWindow) {
            return null
        }
        const refDate = DateTime.fromFormat(taskDateWindow, "y-LL-dd", { zone: timezone })
        const currentDate = DateTime.now().setZone(timezone)
        const refInCurrentDate = refDate.startOf("day").toISO() === currentDate.startOf("day").toISO()
        const minBoundDate = DateTime.fromMillis(millisBounds[0], { zone: timezone })
        const maxBoundDate = DateTime.fromMillis(millisBounds[1], { zone: timezone })
        type TodayTomorrowYesterday = "Today" | "Tomorrow" | "Yesterday" | null

        function todayTomorrowYesterday(a: DateTime, b: DateTime): TodayTomorrowYesterday {
            if (a.plus({ days: 1 }).startOf("day").toISO() === b.startOf("day").toISO()) {
                return "Tomorrow"
            }
            if (a.minus({ days: 1 }).startOf("day").toISO() === b.startOf("day").toISO()) {
                return "Yesterday"
            }
            if (a.startOf("day").toISO() === b.startOf("day").toISO()) {
                return "Today"
            }
            return null
        }

        let minBoundTodayTomorrowYesterday: TodayTomorrowYesterday = todayTomorrowYesterday(refDate, minBoundDate)
        let maxBoundTodayTomorrowYesterday: TodayTomorrowYesterday = todayTomorrowYesterday(refDate, maxBoundDate)

        const minBoundFormattedStr = (minBoundTodayTomorrowYesterday !== null && refInCurrentDate)
            ? `${minBoundTodayTomorrowYesterday}, ${minBoundDate.toFormat("t")}`
            : minBoundDate.toFormat("LLL d, t")

        const maxBoundFormattedStr = (maxBoundTodayTomorrowYesterday !== null && refInCurrentDate)
            ? `${maxBoundTodayTomorrowYesterday}, ${maxBoundDate.toFormat("t")}`
            : maxBoundDate.toFormat("LLL d, t")

        return minBoundFormattedStr + " - " + maxBoundFormattedStr
    }



    const renderTaskDateWindow = (taskData: OnfleetTask, orgTimezone: string | undefined) => {
        if (!taskData) {
            return null
        }
        const completeAfter = taskData.completeAfter
        const completeBefore = taskData.completeBefore
        if (!orgTimezone) {
            return null
        }
        if (completeAfter && completeBefore) {
            const completeAfterTime = DateTime.fromMillis(completeAfter, { zone: orgTimezone }).toFormat("h:mm a")
            const completeAfterDate = DateTime.fromMillis(completeAfter, { zone: orgTimezone }).toFormat("ccc LLL d")
            const completeBeforeTime = DateTime.fromMillis(completeBefore, { zone: orgTimezone }).toFormat("h:mm a")
            const completeBeforeDate = DateTime.fromMillis(completeBefore, { zone: orgTimezone }).toFormat("ccc LLL d")
            let formattedWindow = completeAfterDate + ", " + completeAfterTime + " - " + completeBeforeDate + ", " + completeBeforeTime
            if (completeAfterDate === completeBeforeDate) {
                formattedWindow = completeBeforeDate + ", " + completeAfterTime + " - " + completeBeforeTime
            }
            return <div className="font-bold text-xs text-gray-500 sm:text-sm">{formattedWindow}</div>
        }
        if (completeBefore) {
            const completeBeforeTime = DateTime.fromMillis(completeBefore, { zone: orgTimezone }).toFormat("h:mm a")
            const completeBeforeDate = DateTime.fromMillis(completeBefore, { zone: orgTimezone }).toFormat("ccc LLL d")
            const formattedWindow = `Before: ${completeBeforeDate}, ${completeBeforeTime}`
            return <div className="font-bold text-xs text-gray-500 sm:text-sm">{formattedWindow}</div>
        }
        if (completeAfter) {
            const completeAfterTime = DateTime.fromMillis(completeAfter, { zone: orgTimezone }).toFormat("h:mm a")
            const completeAfterDate = DateTime.fromMillis(completeAfter, { zone: orgTimezone }).toFormat("ccc LLL d")
            const formattedWindow = `After: ${completeAfterDate}, ${completeAfterTime}`
            return <div className="font-bold text-xs text-gray-500 sm:text-sm">{formattedWindow}</div>
        }

        return null
    }

    const taskInBounds = (t: OnfleetTask) => {
        const minBoundMillis = millisBounds[0]
        const maxBoundMillis = millisBounds[1]

        if (t.completeBefore && t.completeAfter) {
            return t.completeAfter <= maxBoundMillis && t.completeBefore >= minBoundMillis
        }
        if (t.completeAfter) {
            return t.completeAfter <= maxBoundMillis
        }
        if (t.completeBefore) {
            return t.completeBefore >= minBoundMillis
        }
        return true
    }


    const renderOrgTasks = (taskMap: { [taskId: string]: OnfleetTask }) => {
        return globalUnassignedTaskIds.map(taskId => {
            const taskData = taskMap[taskId]
            if (!taskData) {
                return null
            }
            const destination = taskData.destination.address
            const { number, street, city } = destination
            const shortAddress = (number ? `${number} ` : "")
                + (street ? `${street}, ` : "") + (city ? `${city}` : "")

            const recipientName = taskData.recipients[0]?.name
            const isOrgContainer = taskData.container?.type === "ORGANIZATION"

            if (!isOrgContainer) {
                return null
            }
            if (!taskInBounds(taskData)) {
                return null
            }

            return (
                <div
                    key={taskId}
                    className={`select-none flex p-1 mb-1 border border-black hover:border hover:border-dark rounded cursor-pointer ${taskIdsSelected.includes(taskId) ? "bg-dark" : ""}`}
                    onClick={(e) => handleTaskSelectionToggle(taskId, e, "global_unassigned")}
                >
                    <div>
                        <span className="material-symbols-outlined text-sm">
                            location_on
                        </span>
                    </div>
                    <div className="ml-2">
                        <div className="text-xs sm:text-sm font-bold">{shortAddress}</div>
                        {
                            recipientName &&
                            <div className="text-xs sm:text-sm">{recipientName}</div>
                        }
                        {renderTaskDateWindow(taskData, timezone)}
                    </div>
                </div>
            )
        })
    }
    const renderTeamTasks = (taskIds: string[], taskMap: { [taskId: string]: OnfleetTask }, teamId: string) => {
        return taskIds
            .filter(taskId => {
                const taskData = taskMap[taskId]
                return !!taskData && taskInBounds(taskData)
            })
            .map((taskId) => {
                const taskData = taskMap[taskId]
                const destination = taskData.destination.address
                const { number, street, city } = destination
                const shortAddress = (number ? `${number} ` : "")
                    + (street ? `${street}, ` : "") + (city ? `${city}` : "")
                const recipientName = taskData.recipients[0]?.name

                return (
                    <div
                        key={taskId}
                        className={`select-none flex p-1 mb-1 border border-black hover:border hover:border-dark rounded cursor-pointer ${taskIdsSelected.includes(taskId) ? "bg-dark" : ""}`}
                        onClick={(e) => handleTaskSelectionToggle(taskId, e, "team", teamId)}
                    >
                        <div>
                            <span className="material-symbols-outlined text-sm">
                                location_on
                            </span>
                        </div>
                        <div className="ml-2">
                            <div className="text-xs sm:text-sm font-bold">{shortAddress}</div>
                            {
                                recipientName &&
                                <div className="text-xs sm:text-sm">{recipientName}</div>
                            }
                            {renderTaskDateWindow(taskData, timezone)}
                        </div>
                    </div>
                )
            })
    }

    const renderTeamWorkers = (workerIds: string[], workerMap: { [workerId: string]: OnfleetWorker }) => {
        return workerIds
            .filter(workerId => {
                const workerData = workerMap[workerId]
                return !!workerData
            })
            .map((workerId, idx, arr) => {
                const workerData = workerMap[workerId]
                return (
                    <div key={workerId} className={`flex p-1 pl-4 text-gray-500 ${idx === arr.length - 1 ? "border-b-2 border-dark" : ""}`}>
                        <div className="pl-2 text-xs sm:text-sm">{`${workerData.name} [ ${workerData.tasks.length} tasks ]`}</div>
                    </div>
                )
            })
    }

    const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target
        setTaskDateWindow(value)
    }

    const handleHourBoundsChange = (bounds: number | number[]) => {
        // to avoid crossover
        if (!Array.isArray(bounds)) {
            return
        }
        const min = bounds[0]
        const max = bounds[1]
        if (max - min >= 1) {
            setHourBounds([min, max])
        }
    }

    const getMillisTimeBounds = (date: string, [min, max]: [number, number], timezone?: string): [number, number] => {
        const selectedDate = timezone
            ? DateTime.fromFormat(date, "y-LL-dd", { zone: timezone }).startOf("day")
            : DateTime.fromFormat(date, "y-LL-dd").startOf("day")

        const minMillis = selectedDate.plus({ hours: min }).toMillis()
        const maxMillis = selectedDate.plus({ hours: max }).toMillis()
        return [minMillis, maxMillis]
    }

    /* const handleTaskFilterChange = (e: ChangeEvent<HTMLSelectElement>) => { */
    /*     const value = e.target.value as "window_filtered" | "none" */
    /*     if (value === "window_filtered") { */
    /*         if (timezone) { */
    /*             setTaskDateWindow(DateTime.now().setZone(timezone).toFormat("yyyy-MM-dd")) */
    /*         } else { */
    /*             setTaskDateWindow(DateTime.now().toFormat("yyyy-MM-dd")) */
    /*         } */
    /*     } else { */
    /*         setTaskDateWindow(null) */
    /*     } */
    /**/
    /*     setTaskFilter(value) */
    /* } */

    return (
        <div className="w-1/3 flex flex-col overflow-y-auto">
            <div className="mr-2">
                <div className="pl-4 mb-4 text-xs sm:text-sm">
                    <div
                        className="flex items-center justify-center p-2 rounded border border-dark mb-4 hover:border-gray-500 hover:cursor-pointer"
                        onClick={() => roActions.triggerFetches({
                            teams: true,
                            tasks: true,
                            workers: true
                        })}
                    >
                        <div className="mr-2">Resync tasks</div>
                        <span className="material-symbols-outlined">
                            sync
                        </span>
                    </div>
                    {
                        taskFilter === "window_filtered" &&
                        (
                            <div className="mt-4">
                                <input
                                    type="date"
                                    name="date"
                                    className="bg-dark border-none rounded outline-none p-1 w-full mb-2"
                                    onChange={handleDateChange}
                                    value={taskDateWindow ?? ""}
                                />
                                {
                                    timezone &&
                                    (
                                        <div className="text-gray-500 text-xs sm:text-sm mb-2">
                                            {`Timezone: ` + timezone}
                                        </div>
                                    )
                                }
                                <Slider
                                    min={-6}
                                    max={30}
                                    step={0.5}
                                    range
                                    value={hourBounds}
                                    allowCross={false}
                                    onChange={handleHourBoundsChange}
                                    trackStyle={{ backgroundColor: "#AC79F7" }}
                                    railStyle={{ backgroundColor: "#6b7280" }}
                                    handleStyle={{
                                        backgroundColor: "#d1d5db",
                                        outline: "none",
                                        boxShadow: "none",
                                        border: "none",
                                        opacity: 100
                                    }}
                                />
                                <div className="flex justify-center items-center mt-1 text-xs sm:text-sm">
                                    {formatSliderTimeWindow()}
                                </div>
                            </div>
                        )
                    }
                    {/* <select */}
                    {/*     className="outline-none pr-2 py-1 w-full bg-black border-b border-dark rounded appearance-none mt-2 cursor-pointer text-gray-500" */}
                    {/*     onChange={handleTaskFilterChange} */}
                    {/*     value={taskFilter} */}
                    {/* > */}
                    {/*     <option value="window_filtered">filtering tasks by date</option> */}
                    {/*     <option value="none">showing all tasks created in last month</option> */}
                    {/* </select> */}
                </div>
                <div className="mb-2 p-1 sm:p-2 flex-col text-xs sm:text-base">
                    <div className="flex">
                        <span
                            className="material-symbols-outlined cursor-pointer"
                            onClick={() => setActiveGlobalUnassignedMenu(state => !state)}
                        >
                            {
                                activeGlobalUnassignedMenu
                                    ? "arrow_drop_down"
                                    : "arrow_right"
                            }
                        </span>
                        <div className="ml-1 mb-2 flex items-center">
                            <div
                                onClick={() => setActiveGlobalUnassignedMenu(state => !state)}
                                className="cursor-pointer"
                            >
                                Unassigned
                            </div>
                            {
                                activeGlobalUnassignedMenu &&
                                (
                                    <div
                                        className={`ml-8 text-xs sm:text-sm text-gray-500 ${globalUnassignedTaskIds.length > 0 ? "text-purple cursor-pointer hover:text-purple-light border-b border-b-purple" : ""}`}
                                        onClick={globalUnassignedTaskIds.length > 0 ? () => handleTeamTasksSelectAllToggle() : () => ({})}
                                    >
                                        {globalUnassignedTaskIds.length > 0 ? "select all" : "no tasks"}
                                    </div>
                                )
                            }

                        </div>
                    </div>
                    {
                        (activeGlobalUnassignedMenu && loadingTasks)
                            ? <div className="flex justify-center items-center my-2"><div className="loading-spinner" /></div>
                            : activeGlobalUnassignedMenu
                                ? renderOrgTasks(tasks)
                                : null
                    }
                </div>
                {
                    loadingTeams
                        ? (
                            <div className="flex justify-center mt-4">
                                <div className="loading-spinner" />
                            </div>
                        )
                        : (
                            teams?.map(team => {
                                const menuActive = activeTeamMenus.has(team.id)
                                const teamTypeless = team as any
                                const teamTasks = teamTypeless.tasks
                                const teamWorkers = team.workers
                                return (
                                    <div
                                        key={team.id}
                                        className="mb-2 p-1 sm:p-2 flex-col text-xs sm:text-base"
                                    >
                                        <div className="flex">
                                            <span
                                                className="material-symbols-outlined cursor-pointer"
                                                onClick={() => handleTeamMenuActiveToggle(team.id)}
                                            >
                                                {
                                                    menuActive
                                                        ? "arrow_drop_down"
                                                        : "arrow_right"
                                                }
                                            </span>
                                            <div className="ml-1 mb-2 flex items-center">
                                                <div
                                                    onClick={() => handleTeamMenuActiveToggle(team.id)}
                                                    className="cursor-pointer"
                                                >
                                                    {team.name}
                                                </div>
                                                {
                                                    menuActive &&
                                                    (
                                                        <div
                                                            className={`ml-8 text-xs sm:text-sm text-gray-500 ${teamTasks.length > 0 ? "text-purple cursor-pointer hover:text-purple-light border-b border-b-purple" : ""}`}
                                                            onClick={teamTasks.length > 0 ? () => handleTeamTasksSelectAllToggle(team.id) : () => ({})}
                                                        >
                                                            {teamTasks.length > 0 ? "select all" : "no tasks"}
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        {
                                            (menuActive && loadingTasks)
                                                ? <div className="flex justify-center items-center my-2"><div className="loading-spinner" /></div>
                                                : menuActive
                                                    ? renderTeamTasks(teamTasks, tasks, team.id)
                                                    : null
                                        }
                                        {
                                            (menuActive && loadingWorkers)
                                                ? <div className="flex justify-center items-center my-2"><div className="loading-spinner" /></div>
                                                : menuActive
                                                    ? renderTeamWorkers(teamWorkers, workers)
                                                    : null
                                        }
                                    </div>
                                )
                            })

                        )
                }
            </div>
        </div>
    )
}
