import { useState, useEffect, useRef } from "react"
import { useUserStore, useOrgStore, useShadowOrgStore } from "stores/global"

// auth
import { signoutUser, authStateSubscribe } from "calls/auth"

// fb
import { auth } from "fb"

// db
import { dbOrgListen, dbGetOrgsAll } from "calls/db"

// token
import { tokenFetch } from "calls/token"

// components
import AccountSettings from "components/account_settings/AccountSettings"
import AutoDispatch from "components/auto_dispatch/AutoDispatch"
import Loading from "pages/Loading"
import RouteOptimization from "components/route_optimization/RouteOptimization"
import Signin from "pages/Signin"

// assets
import OnfleetSVG from "assets/OnfleetSVG"

// hooks
import { useDeepCompareEffect } from "hooks"

// types
import type { ChangeEvent } from "react"
import type { DbOrg, DbService } from "../../types/lib/db"

export default function App() {
    const initRender = useRef(true)
    const refTokenFetched = useRef(false)
    const user = useUserStore(state => state.user)
    const setUser = useUserStore(state => state.setUser)
    const org = useOrgStore(state => state.org)
    const shadowOrg = useShadowOrgStore(state => state.shadowOrg)

    const determinedOrg = (shadowOrg && user?.role === "super") ? shadowOrg : org

    const setOrg = useOrgStore(state => state.setOrg)

    const [token, setToken] = useState("")
    const [serviceSelected, setServiceSelected] = useState("")
    const [globalMessage, setGlobalMessage] = useState({ type: "info", body: "" })
    const [loading, setLoading] = useState(true)

    const [forceRemountKeys, setForceRemountKeys] = useState({
        route_optimization: "ro-1",
        auto_dispatch: "ad-1"
    })

    const [superAllOrgs, setSuperAllOrgs] = useState([])

    useEffect(() => {
        const unsub = authStateSubscribe(setUser, setOrg)
        return function cleanup() {
            if (typeof unsub === "function") {
                unsub()
            }
        }
    }, [])

    useDeepCompareEffect(() => {
        if (serviceSelected) {
            setForceRemountKeys(state => {
                const currentServiceRemountKey = state[serviceSelected as DbService]
                const splitKey = currentServiceRemountKey.split("-")
                const count = parseInt(splitKey[1])
                splitKey[1] = (count + 1).toString()
                const updatedKey = splitKey.join("-")
                return {
                    ...state,
                    [serviceSelected]: updatedKey
                }
            })
        }
    }, [shadowOrg])

    useEffect(() => {
        if (user && superAllOrgs.length === 0 && user.role === "super") {
            dbGetOrgsAll(setSuperAllOrgs)
        }
    }, [user])

    useEffect(() => {
        const service = localStorage.getItem("service_selected")
        if (service && ["route_optimization", "auto_dispatch"].includes(service)) {
            setServiceSelected(service)
        } else if (determinedOrg) {
            const orgServices = Object.values(determinedOrg.services)
            const firstEnabledService = orgServices.find(s => s.service_enabled)
            if (!firstEnabledService) {
                return
            }
            setServiceSelected(firstEnabledService.id)
        }
        initRender.current = false
    }, [determinedOrg])

    useEffect(() => {
        async function load() {
            try {
                const currentUser = auth.currentUser
                if (!currentUser) {
                    throw new Error(`user isn't authenticated`)
                }
                if (!determinedOrg) {
                    throw new Error(`invalid org`)
                }

                const idToken = await currentUser.getIdToken(true)
                await tokenFetch(idToken, determinedOrg.id, setToken)
            } catch (err: any) {
                console.error(err)
            } finally {
                refTokenFetched.current = false
            }
        }
        if (user && determinedOrg && !refTokenFetched.current) {
            load()
        }
    }, [determinedOrg, user])

    useEffect(() => {
        if (user) {
            const unsub = dbOrgListen(user.orgId, setOrg)
            return function cleanup() {
                if (typeof unsub === "function") {
                    unsub()
                }
            }
        }
    }, [user?.orgId])

    const sortServicesDropdown = (org: DbOrg) => {
        const orgServices = org.services
        const orgServiceVals = Object.values(orgServices)
        const result = []
        for (const service of orgServiceVals) {
            result.push(service)
        }

        result.sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
        })
        return result
    }

    const handleServiceSelectedChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target
        setServiceSelected(value)
        localStorage.setItem("service_selected", value)
    }


    function renderServiceSelected(serviceSelected: DbService) {
        if (!user || !determinedOrg) {
            return null
        }
        switch (serviceSelected) {
            case "route_optimization":
                if (determinedOrg) {
                    return (
                        <RouteOptimization
                            key={forceRemountKeys.route_optimization}
                            setGlobalMessage={setGlobalMessage}
                            org={determinedOrg}
                            token={token}
                        />
                    )
                }
                return null
            case "auto_dispatch":
                if (determinedOrg) {
                    return (
                        <AutoDispatch
                            key={forceRemountKeys.auto_dispatch}
                            org={determinedOrg}
                            token={token}
                        />
                    )
                }
                return null
            default:
                return null
        }
    }

    if (!user && loading) {
        setTimeout(() => {
            setLoading(false)
        }, 2000)
        return <Loading />
    }

    if (!user) {
        return (
            <Signin />
        )
    }

    return (
        <div className="h-screen text-gray-400">
            {
                (org && (org.account_type === "test" || org.account_type === "internal")) &&
                <div className="p-2 flex justify-center text-sm font-semibold bg-gradient-to-l from-purple to-blue text-white">
                    {`${org.account_type.toUpperCase()} MODE`}
                </div>
            } <div className="flex items-center h-16 bg-dark p-5 opacity-90 justify-between">
                <div className="flex items-center">
                    <OnfleetSVG />
                    {
                        (user && determinedOrg) &&
                        (
                            <select
                                className="ml-6 p-1.5 rounded w-30 sm:w-44 bg-black outline-none text-xs sm:text-sm appearance-none"
                                value={serviceSelected}
                                onChange={handleServiceSelectedChange}
                            >
                                {sortServicesDropdown(determinedOrg).map(s => {
                                    return (
                                        <option
                                            value={s.id}
                                            key={s.id}
                                            disabled={!s.service_enabled}
                                        >
                                            {s.name}
                                        </option>
                                    )
                                })}
                            </select>
                        )
                    }
                </div>
                <div className={`${globalMessage.type === "info" ? "text-purple" : "text-red-error"}`}>{globalMessage.body}</div>
                <div className="flex items-center">
                    <label htmlFor="account-settings-modal" className="btn modal-button mr-6 bg-dark hover:bg-black border-none">
                        <span className="material-symbols-outlined text-gray-300 cursor-pointer">
                            manage_accounts
                        </span>
                    </label>
                    <span className="material-symbols-outlined text-gray-300 cursor-pointer" onClick={signoutUser}>
                        logout
                    </span>
                </div>
            </div>
            {
                renderServiceSelected(serviceSelected as DbService)
            }
            {
                (determinedOrg && serviceSelected) &&
                <AccountSettings
                    superAllOrgs={superAllOrgs}
                    org={determinedOrg}
                    serviceSelected={serviceSelected as DbService}
                />
            }
        </div>
    )
}
