import { useState } from "react"
import { DateTime } from "luxon"

// stores
import { useOptStore } from "stores/opt"

// db
import { dbOrgAutoDispatchOptimizationSettingsUpdate } from "calls/db"

// utils
import {
    vehicleTypes,
    vehicleSpeedUnitTypes
} from "utils/constants"

import type { Message } from "types"
import type {
    DbOptimizationSettings,
    DbOrg,
} from "@/../../../types/lib/db"

const intHours = Array.from({ length: 12 }, (_, i) => i + 1)
const intMinutes = Array.from({ length: 60 }, (_, i) => i).filter(m => m % 5 === 0)

type ConfigurationSettingsProps = {
    org: DbOrg
}

export default function ConfigurationSettings({
    org
}: ConfigurationSettingsProps) {
    const optimizationSettings = useOptStore(state => state.ad.state.optimization_settings)
    const optActions = useOptStore(state => state.actions)

    const [message, setMessage] = useState<Message>({ type: "info", body: "" })
    const [savingOptimizationSettings, setSavingOptimizationSettings] = useState(false)

    const handleSubmitSaveOptimizationSettings = async (optimizationSettings: DbOptimizationSettings) => {
        setMessage({ type: "info", body: "" })
        setSavingOptimizationSettings(true)
        try {
            const orgId = org.id
            const validSchedule = () => {
                const currentDateFormatted = DateTime.now().toUTC().toFormat("yyyy-MM-dd")
                const shiftStart = optimizationSettings.worker_schedule.shift_start
                const shiftEnd = optimizationSettings.worker_schedule.shift_end
                const shiftStartFormatted = `${shiftStart.hour.toString().padStart(2, "0")}:${shiftStart.minute.toString().padStart(2, "0")} ${shiftStart.modifier}`
                const shiftEndFormatted = `${shiftEnd.hour.toString().padStart(2, "0")}:${shiftEnd.minute.toString().padStart(2, "0")} ${shiftEnd.modifier}`
                const dateStrShiftStart = `${currentDateFormatted} ${shiftStartFormatted}`
                const dateStrShiftEnd = `${currentDateFormatted} ${shiftEndFormatted}`
                const dateTimeShiftStart = DateTime.fromFormat(dateStrShiftStart, "yyyy-MM-dd hh:mm a")
                const dateTimeShiftEnd = DateTime.fromFormat(dateStrShiftEnd, "yyyy-MM-dd hh:mm a")
                return dateTimeShiftEnd > dateTimeShiftStart
            }
            if (!validSchedule()) {
                setMessage({ type: "error", body: "worker schedule shift end must be later than shift start" })
                setSavingOptimizationSettings(false)
                return
            }
            await dbOrgAutoDispatchOptimizationSettingsUpdate(orgId, optimizationSettings)
            setMessage({ type: "info", body: "optimization settings saved" })
        } catch (err) {
            console.error(err)
            setMessage({ type: "error", body: "oops. an unexpected error occurred" })
        }
        setSavingOptimizationSettings(false)
    }

    const hiddenAdElements = org.services.auto_dispatch.hidden

    return (
        <div className="flex flex-col items-center w-11/12 text-sm sm:text-base mt-5">
            <div className="w-full h-full flex flex-col items-center overflow-y-auto">
                <div className="mt-4 w-full flex justify-center sm:justify-start">Optimization Settings</div>
                <div className="flex w-full flex-col sm:flex-row items-center sm:items-start">
                    <div className="w-full sm:w-1/2 sm:mr-2">
                        <div className="mt-1">
                            <label className="block text-sm mb-1.5 mt-2">Maximum number of tasks per route</label>
                            <div className="flex items-center">
                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    name="route_max_tasks"
                                    className="w-1/3 bg-gray-300 border border-gray-500 text-gray-900 text-sm rounded-md block p-0.5 outline-none"
                                    onChange={(e) => {
                                        optActions.updateOptimizationSettingsNumerical("ad", "route_max_tasks", e.target.value)
                                    }}
                                    value={optimizationSettings.route_max_tasks}
                                /> <div className="w-1/3 ml-2 text-xs">tasks</div>
                            </div>
                            <label className="block mb-1.5 text-sm mt-2">Service Time per task</label>
                            <div className="flex items-center">
                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    name="task_service_time"
                                    className="w-1/3 bg-gray-300 border border-gray-500 text-gray-900 text-sm rounded-md block p-0.5 outline-none"
                                    onChange={(e) => {
                                        optActions.updateOptimizationSettingsNumerical("ad", "task_service_time", e.target.value)
                                    }}
                                    value={optimizationSettings.task_service_time}
                                />
                                <div className="w-1/3 ml-2 text-xs">minutes</div>
                            </div>
                            {
                                !hiddenAdElements.includes("task_max_delay") &&
                                <div>
                                    <label className="block mb-1.5 text-sm mt-2">Maximum allowed delay per task</label>
                                    <div className="flex items-center">
                                        <input
                                            type="number"
                                            min="0"
                                            step="1"
                                            name="task_max_delay"
                                            className="w-1/3 bg-gray-300 border border-gray-500 text-gray-900 text-sm rounded-md block p-0.5 outline-none"
                                            onChange={(e) => {
                                                optActions.updateOptimizationSettingsNumerical("ad", "task_max_delay", e.target.value)
                                            }}
                                            value={optimizationSettings.task_max_delay}
                                        />
                                        <div className="w-1/3 ml-2 text-xs">minutes</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 sm:ml-2">
                        <div className="mt-1">
                            <label className="block mb-1.5 text-sm mt-2">Default driver vehicle capacity</label>
                            <div className="flex items-center">
                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    name="worker_capacity"
                                    className="w-1/3 bg-gray-300 border border-gray-500 text-gray-900 text-sm rounded-md block p-0.5 outline-none"
                                    onChange={(e) => {
                                        optActions.updateOptimizationSettingsNumerical("ad", "worker_capacity", e.target.value)
                                    }}
                                    value={optimizationSettings.worker_capacity}
                                />
                            </div>
                            {/* <label className="block mb-1.5 text-sm mt-2">Default task quantity</label> */}
                            {/* <div className="flex items-center"> */}
                            {/*     <input */}
                            {/*         type="number" */}
                            {/*         name="task_quantity" */}
                            {/*         className="w-1/3 bg-gray-300 border border-gray-500 text-gray-900 text-sm rounded-md block p-0.5 outline-none" */}
                            {/*         onChange={handleOptimizationSettingsChange} */}
                            {/*         value={optimizationSettings.task_quantity} */}
                            {/*     /> */}
                            {/* </div> */}
                            <label className="block mb-1.5 text-sm mt-2">Default driver schedule</label>
                            <div className="flex flex-col justify-center text-sm">
                                <div>
                                    <select
                                        className="p-1 rounded-md bg-dark outline-none appearance-none"
                                        onChange={(e) => {
                                            optActions.updateOptimizationSettingsWorkerSchedule(
                                                "ad",
                                                "shift_start",
                                                "hour",
                                                e.target.value
                                            )
                                        }}
                                        value={optimizationSettings.worker_schedule.shift_start.hour}
                                        name="hour"
                                    >
                                        {intHours.map(h => {
                                            return <option value={h} key={h}>{h.toString()}</option>
                                        })}
                                    </select>
                                    <span className="mx-2">:</span>
                                    <select
                                        className="p-1 rounded-md bg-dark outline-none appearance-none"
                                        onChange={(e) => {
                                            optActions.updateOptimizationSettingsWorkerSchedule(
                                                "ad",
                                                "shift_start",
                                                "minute",
                                                e.target.value
                                            )
                                        }}
                                        value={optimizationSettings.worker_schedule.shift_start.minute}
                                        name="minute"
                                    >
                                        {intMinutes.map(m => {
                                            return <option value={m} key={m}>{m.toString().length === 1 ? `0${m}` : `${m}`}</option>
                                        })}
                                    </select>
                                    <select
                                        className="p-1 rounded-md bg-dark outline-none sm:mx-1 mt-1 sm:mt-0 appearance-none"
                                        onChange={(e) => {
                                            optActions.updateOptimizationSettingsWorkerSchedule(
                                                "ad",
                                                "shift_start",
                                                "modifier",
                                                e.target.value
                                            )
                                        }}
                                        value={optimizationSettings.worker_schedule.shift_start.modifier}
                                        name="modifier"
                                    >
                                        <option value="PM">PM</option>
                                        <option value="AM">AM</option>
                                    </select>
                                    <span className="mx-2 text-sm">shift start</span>
                                </div>
                                <div>
                                    <select
                                        className="p-1 rounded-md bg-dark outline-none mt-1 appearance-none"
                                        onChange={(e) => {
                                            optActions.updateOptimizationSettingsWorkerSchedule(
                                                "ad",
                                                "shift_end",
                                                "hour",
                                                e.target.value
                                            )
                                        }}
                                        value={optimizationSettings.worker_schedule.shift_end.hour}
                                        name="hour"
                                    >
                                        {intHours.map(h => {
                                            return <option value={h} key={h}>{h.toString()}</option>
                                        })}
                                    </select>
                                    <span className="mx-2">:</span>
                                    <select
                                        className="p-1 rounded-md bg-dark outline-none appearance-none"
                                        onChange={(e) => {
                                            optActions.updateOptimizationSettingsWorkerSchedule(
                                                "ad",
                                                "shift_end",
                                                "minute",
                                                e.target.value
                                            )
                                        }}
                                        value={optimizationSettings.worker_schedule.shift_end.minute}
                                        name="minute"
                                    >
                                        {intMinutes.map(m => {
                                            return <option value={m} key={m}>{m.toString().length === 1 ? `0${m}` : `${m}`}</option>
                                        })}
                                    </select>
                                    <select
                                        className="p-1 rounded-md bg-dark outline-none sm:mx-1 mt-1 sm:mt-0 appearance-none"
                                        onChange={(e) => {
                                            optActions.updateOptimizationSettingsWorkerSchedule(
                                                "ad",
                                                "shift_end",
                                                "modifier",
                                                e.target.value
                                            )
                                        }}
                                        value={optimizationSettings.worker_schedule.shift_end.modifier}
                                        name="modifier"
                                    >
                                        <option value="PM">PM</option>
                                        <option value="AM">AM</option>
                                    </select>
                                    <span className="mx-2 text-sm">shift end</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !hiddenAdElements.includes("worker_vehicle_speed") &&
                    (
                        <div className="w-full">
                            <div className="w-full border-b-2 border-dark mt-8" />
                            <div className="w-full mt-4">
                                <div className="flex flex-col">
                                    <label>Vehicle Speeds</label>
                                    <label className="text-sm mt-1 text-gray-500">* speeds will be applied based on Onfleet worker vehicle type *</label>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full mt-3">
                                    {vehicleTypes.map((vehicleType, index, arr) => {
                                        let icon = null
                                        switch (vehicleType) {
                                            case "car":
                                                icon = (
                                                    <span className="material-symbols-outlined">
                                                        directions_car
                                                    </span>
                                                )
                                                break
                                            case "truck":
                                                icon = (
                                                    <span className="material-symbols-outlined">
                                                        local_shipping
                                                    </span>
                                                )
                                                break
                                            case "motorcycle":
                                                icon = (
                                                    <span className="material-symbols-outlined">
                                                        two_wheeler
                                                    </span>
                                                )
                                                break
                                            case "bicycle":
                                                icon = (
                                                    <span className="material-symbols-outlined">
                                                        directions_bike
                                                    </span>
                                                )
                                                break
                                            case "walking":
                                                icon = (
                                                    <span className="material-symbols-outlined">
                                                        directions_walk
                                                    </span>
                                                )
                                                break
                                            default:
                                                break
                                        }
                                        return (
                                            <div className={`w-full sm:w-1/4 ${index !== arr.length - 1 ? "sm:mr-2" : ""}`} key={vehicleType}>
                                                <div>{icon !== null ? icon : vehicleType}</div>
                                                <div className="flex mt-1 mb-5 sm:mb-0">
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        step="1"
                                                        name="value"
                                                        className="w-40 sm:w-14 bg-black border border-dark rounded-md text-sm block p-1 outline-none"
                                                        onChange={(e) => {
                                                            optActions.updateOptimizationSettingsVehicleSpeed(
                                                                "ad",
                                                                vehicleType,
                                                                "value",
                                                                e.target.value
                                                            )
                                                        }}
                                                        value={optimizationSettings.worker_vehicle_speed[vehicleType].value}
                                                    />
                                                    <select
                                                        className="ml-2 p-0.5 rounded-md bg-dark outline-none appearance-none text-xs sm:text-sm"
                                                        onChange={(e) => {
                                                            optActions.updateOptimizationSettingsVehicleSpeed(
                                                                "ad",
                                                                vehicleType,
                                                                "unit",
                                                                e.target.value
                                                            )
                                                        }}
                                                        name="unit"
                                                        value={optimizationSettings.worker_vehicle_speed[vehicleType].unit}
                                                    >
                                                        {vehicleSpeedUnitTypes.map(unit => {
                                                            return <option value={unit} key={unit}>{unit}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="w-full border-b-2 border-dark mt-8" />
                <div
                    className="min-h-[2.5rem] flex w-full items-center justify-center text-whitish p-1 rounded cursor-pointer mt-8 mb-5 hover:bg-gray-900 bg-dark"
                    onClick={savingOptimizationSettings ? () => { } : () => handleSubmitSaveOptimizationSettings(optimizationSettings)}
                >

                    {
                        savingOptimizationSettings
                            ? <span className="loading-spinner" />
                            : <span>save</span>
                    }
                </div>
                <div className={`${message.type === "error" ? "text-red-error" : "text-purple"}`}>
                    {message.body}
                </div>
            </div>
        </div>
    )
}
