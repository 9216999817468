import { useOptStore } from "stores/opt"

// types
import type {
    OnfTasksListResponseData
} from "@/../../../../../types/services/onfleet"

type PreRunReviewProps = {
    tasks: OnfTasksListResponseData,
    taskIdsSelected: string[],
    setSummaryDetailsPanelActive: (active: boolean) => void,
    handleProcessOptimizationSubmit: () => Promise<void>,
    /* driverSelections: DriverSelections */
}

export default function PreRunReview({
    tasks,
    taskIdsSelected,
    setSummaryDetailsPanelActive,
    handleProcessOptimizationSubmit,
    /* driverSelections */
}: PreRunReviewProps) {
    const roStore = useOptStore(state => state.ro)
    const optActions = useOptStore(state => state.actions)
    const optimizationSettings = useOptStore(state => state.ro.state.optimization_settings)
    const allWorkersSelected = roStore.state.workers_selected_all
    const taskDataArr = Object.values(tasks)
    const tasksSelectedWithoutTimeWindows = taskDataArr.filter(t => {
        return taskIdsSelected.includes(t.id) && (!t.completeAfter || !t.completeBefore)
    })

    return (
        <div className="flex flex-col items-center w-2/3 text-sm sm:text-base border-l-2 border-dark ml-8">
            <div className="w-full flex items-center p-2">
                <span
                    className="material-symbols-outlined hover:cursor-pointer text-3xl"
                    onClick={() => setSummaryDetailsPanelActive(false)}
                >
                    arrow_back
                </span>
            </div>
            <div className="w-full flex flex-col items-center px-4 pb-4 overflow-y-auto">
                <div className="text-lg">
                    Review and Confirm
                </div>
                {
                    tasksSelectedWithoutTimeWindows.length > 0
                    && (
                        <div className="mt-4 w-3/4 border border-yellow border-opacity-60 p-4 rounded">
                            <div>Warning. The following tasks do not have a delivery time window specified which may impact optimization results.</div>
                            <div className="border border-black my-3" />
                            {tasksSelectedWithoutTimeWindows.map(t => {
                                return <div className="text-yellow text-opacity-80" key={t.id}>{t.shortId}</div>
                            })}
                        </div>
                    )
                }
                <div className="mt-2 mb-3 flex flex-col items-center p-4 w-2/3">
                    <span className="material-symbols-outlined text-8xl text-purple">
                        local_shipping
                    </span>
                    <div className="text-base mt-4">
                        You have
                        <strong className="text-purple">{` ${taskIdsSelected.length} tasks `}</strong>
                        to optimize across
                        <strong className="text-purple">{` ${allWorkersSelected.length} drivers`}</strong>
                    </div>
                </div>
                <div className="w-2/3 flex justify-between my-2 items-center">
                    <input
                        type="range"
                        name="solver_duration"
                        min="5"
                        max="500"
                        step="5"
                        value={optimizationSettings.solver_duration}
                        className="w-full h-3 bg-dark rounded-lg appearance-none cursor-pointer outline-none accent-gray-400"
                        onChange={(e) => {
                            optActions.updateOptimizationSettingsNumerical("ro", "solver_duration", e.target.value)
                        }}
                    />
                    <label className="ml-5 w-1/4 text-sm sm:text-base">{`${optimizationSettings.solver_duration} seconds`}</label>
                </div>
                <div className="w-2/3 text-[0.9rem] mb-2 p-4">
                    <div>Set maximum duration of optimization processing.</div>
                    <div>Greater time may lead to improved optimization results</div>
                </div>
                <div
                    className="flex items-center justify-center bg-purple text-whitish hover:bg-purple-light p-1 my-9 rounded w-3/4 cursor-pointer"
                    onClick={() => handleProcessOptimizationSubmit()}
                >
                    Confirm
                </div>
            </div>
        </div>
    )
}
