import { useState } from "react"

import { signinUser, passwordResetSend } from "calls/auth"

import OnfleetSVG from "assets/OnfleetSVG"

import type { Message } from "types"

export default function Signin() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [loading, setLoading] = useState(false)
    const [errMessage, setErrMessage] = useState("")

    const [emailPasswordReset, setEmailPasswordReset] = useState("")
    const [processingPasswordReset, setProcessingPasswordReset] = useState(false)
    const [resPasswordReset, setResPasswordReset] = useState<Message>({ type: "info", body: "" })

    return (
        <div>
            <Modal
                processing={processingPasswordReset}
                resMessage={resPasswordReset}
                setProcessingPasswordReset={setProcessingPasswordReset}
                setResPasswordReset={setResPasswordReset}
                email={emailPasswordReset}
                setEmailPasswordReset={setEmailPasswordReset}
            />
            <div className="bg-dark flex justify-center h-screen">
                <div className="hidden bg-cover lg:block lg:w-2/3">
                    <div className="flex items-center h-full px-20 bg-black">
                        <div>
                            <div className="mb-4">
                                <OnfleetSVG />
                            </div>
                            <h2 className="text-3xl font-bold text-gray-300">Advanced Route Optimization</h2>
                            <p className="max-w-xl mt-3 text-gray-300">
                                Offering finely tuned experiences for our customers with more complex operational requirements
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full max-w-md px-8 mx-auto lg:w-2/6">
                    <div className="flex-1">
                        <div className="text-center flex flex-col items-start">
                            <div className="mb-4 lg:hidden">
                                <OnfleetSVG />
                            </div>
                            <h2 className="font-bold text-center text-gray-400 text-xl visible lg:invisible">Advanced Route Optimization</h2>
                        </div>
                        <div className="mt-6">
                            <form onSubmit={(e) => signinUser(e, email, password, setLoading, setErrMessage)}>
                                <div>
                                    <label className="block mb-2 text-sm text-gray-400">Email Address</label>
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="example@example.com"
                                        className="block text-sm w-full px-4 py-2 mt-2 text-gray-400 placeholder-gray-500  rounded-md focus:outline-none bg-black"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="mt-6">
                                    <div className="flex justify-between mb-2">
                                        <label className="block mb-2 text-sm text-gray-400">Password</label>
                                        <label htmlFor="forgot-password-modal" className="text-sm text-gray-400 hover:underline cursor-pointer">Forgot password?</label>
                                    </div>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="Your Password"
                                        className="block text-sm w-full px-4 py-2 mt-2 text-gray-400 placeholder-gray-500  rounded-md focus:outline-none bg-black"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="mt-6">
                                    <button
                                        className="flex items-center justify-center w-full bg-purple opacity-80 px-4 py-2 tracking-wide text-white transition-colors duration-300 transform rounded-md  focus:outline-none hover:bg-purple-light"
                                    >
                                        {
                                            loading &&
                                            <span className="loading-spinner mr-2"></span>
                                        }
                                        Sign in
                                    </button>
                                </div>
                                <div className="text-red-error mt-4 flex justify-center h-5">{errMessage}</div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}

type ModalProps = {
    processing: boolean,
    setProcessingPasswordReset: (processing: boolean) => void,
    setResPasswordReset: (message: Message) => void,
    email: string,
    setEmailPasswordReset: (email: string) => void,
    resMessage: Message
}

function Modal({
    processing,
    setProcessingPasswordReset,
    setResPasswordReset,
    email,
    setEmailPasswordReset,
    resMessage
}: ModalProps) {
    return (
        <div>
            <input type="checkbox" id="forgot-password-modal" className="modal-toggle" />
            <label htmlFor="forgot-password-modal" className="modal cursor-pointer">
                <label className="modal-box relative flex flex-col justify-center items-center bg-dark min-h-[12rem] px-8" htmlFor="">
                    <div className="w-full">
                        <input
                            type="email"
                            name="email"
                            placeholder="email address"
                            className="block text-sm w-full px-4 py-2 mt-2 text-gray-400 placeholder-gray-400  rounded-md focus:outline-none bg-dark border border-black"
                            onChange={(e) => setEmailPasswordReset(e.target.value)}
                            value={email}
                        />
                    </div>
                    <button
                        className="flex text-sm items-center justify-center w-full bg-gray-900 px-4 py-2 tracking-wide text-gray-300 transition-colors duration-300 transform rounded-md  focus:outline-none hover:bg-black mt-4"
                        onClick={() => passwordResetSend(email, setProcessingPasswordReset, setResPasswordReset)}
                    >
                        {
                            processing
                                ? <span className="loading-spinner mr-2"></span>
                                : "reset password"
                        }
                    </button>
                    {
                        resMessage.type === "info"
                            ? <div className="text-purple mt-2 h-2">{resMessage.body}</div>
                            : <div className="text-red-error mt-2 h-2">{resMessage.body}</div>
                    }
                </label>
            </label>
        </div>

    )
}

