import { useEffect, useRef } from "react"
import _ from "lodash"

/*
 * utility
*/
export function useDeepCompareEffect(callback: () => void, dependencies: any[] = []) {
    function useDeepEqualityCheck(value: any) {
        const ref = useRef()
        if (!_.isEqual(value, ref.current)) {
            ref.current = value
        }
        return ref.current
    }
    useEffect(
        callback,
        dependencies.map(useDeepEqualityCheck)
    )
}
