import { useState, useEffect } from "react"
import { reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth"
import { useUserStore, useShadowOrgStore } from "stores/global"
import { useOptStore } from "stores/opt"
import { auth } from "fb"

// constants
import { ErrorMessageGlobalFallback, optMeasures, quantityManagementOptions } from "utils/constants"

// calls
import {
    authAdminUserCreate,
    authListAdmins,
    authDeleteAdmin
} from "calls/auth"

import {
    dbOrgMeasureUpdate,
    dbOrgSimpleQuantityManagementSettingUpdate
} from "calls/db"


// types
import type { User } from "firebase/auth"
import type { ChangeEvent } from "react"
import type { Message } from "types"
import type { DbAdHiddenOption, DbOptMeasure, DbOrg, DbRoHiddenOption, DbService } from "../../../../types/lib/db"
import type { UserRole } from "../../../../types/user"

const settingsHiddenOptions: (DbRoHiddenOption | DbAdHiddenOption)[] = [
    "measure",
    "simple_quantity_management"
]

type AccountSettingsMenuOption = {
    name: string,
    value: AccountMenuOptionValue,
    can_view: UserRole[]
}

type AccountMenuOptionValue = "account"
    | "admins"
    | "settings"

const menuOptions: AccountSettingsMenuOption[] = [
    {
        name: "Account",
        value: "account",
        can_view: ["super", "owner", "admin"]
    },
    {
        name: "Admins",
        value: "admins",
        can_view: ["super", "owner"]
    },
    {
        name: "Settings",
        value: "settings",
        can_view: ["super", "owner", "admin"]
    }
]

const tooltipMeasure = `Street - References street-level data; accounts for speed limits, directionality, and other real world travel impedances. `
    + `In the absence of sufficient data, falls back to the Haversine method and average travel speeds.`
    + `\n`
    + `Haversine - Uses a standard equation to calculate arc distance between two points on the globe, i.e. "as the crow flies". `
    + `Requires the use of average travel speeds to calculate travel time. `
    + `(May be a better option in parts of the world where street-level data is lacking.)`

const tooltipQuantityManagement = `Default - Pickups and Dropoffs are treated as debits or credits to available capacity, respectively. `
    + `Prevents a driver/vehicle from being over capacity at any point in time, but does not restrict the total number of tasks that may be assigned to a driver over the entirety of their route.`
    + `\n`
    + `Simple - Every unlinked Pickup or Dropoff task consumes available capacity. `
    + `Available capacity does not increase following a Dropoff. `
    + `Effectively limits the number of tasks in the route to capacity of the driver/vehicle. `
    + `Linked Pickup/Dropoff pairs are treated as a single task in this calculation. (This resembles legacy Onfleet capacity management)`

type AccountSettingsProps = {
    superAllOrgs: DbOrg[],
    org: DbOrg,
    serviceSelected: DbService
}

export default function AccountSettings({
    superAllOrgs,
    org,
    serviceSelected
}: AccountSettingsProps) {
    const user = useUserStore(state => state.user)
    const setShadowOrg = useShadowOrgStore(state => state.setShadowOrg)
    const optActions = useOptStore(state => state.actions)
    const adState = useOptStore(state => state.ad.state)
    const roState = useOptStore(state => state.ro.state)

    const [email, setEmail] = useState("")
    const [message, setMessage] = useState<Message>({ type: "info", body: "" })
    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [processingAdminCreate, setProcessingAdminCreate] = useState(false)
    const [processingPasswordChange, setProcessingPasswordChange] = useState(false)
    const [processingSettingsChange, setProcessingSettingsChange] = useState(false)
    const [shadowOrgEnabled, setShadowOrgEnabled] = useState(false)
    const [menuOptionSelected, setMenuOptionSelected] = useState<AccountMenuOptionValue>(menuOptions[0].value)
    const [adminUsersList, setAdminUsersList] = useState<User[]>([])
    const [settingsTooltip, setSettingsTooltip] = useState("")

    const isOwnerOrSuper = !!(user && org && ["super", "owner"].includes(user.role))

    const [fetchAdminList, setFetchAdminList] = useState(isOwnerOrSuper)

    const hidden = org.services[serviceSelected].hidden

    const simpleQuantityManagement = serviceSelected === "route_optimization"
        ? roState.simple_quantity_management
        : adState.simple_quantity_management

    const optimizationSettings = serviceSelected === "route_optimization"
        ? roState.optimization_settings
        : adState.optimization_settings

    const optType = serviceSelected === "route_optimization"
        ? "ro"
        : "ad"

    useEffect(() => {
        if (shadowOrgEnabled) {
            setShadowOrg(superAllOrgs[0])
        } else {
            setShadowOrg(null)
        }
    }, [shadowOrgEnabled])

    useEffect(() => {
        async function fetch() {
            try {
                const currentUser = auth.currentUser
                if (!currentUser || !user) {
                    throw new Error(`user isn't authenticated`)
                }
                const idToken = await currentUser.getIdToken(true)
                const orgId = org.id
                await authListAdmins(idToken, orgId, setAdminUsersList)
            } catch (err: any) {
                console.error(err)
                setMessage({
                    type: "error",
                    body: err.message ? err.message : "an unexpected error occurred. please contact support"
                })
            } finally {
                setFetchAdminList(false)
            }
        }
        if (isOwnerOrSuper && fetchAdminList) {
            fetch()
        } else {
            setFetchAdminList(false)
        }
    }, [fetchAdminList, isOwnerOrSuper])

    const handleFieldsResetOnClose = () => {
        setEmail("")
        setCurrentPassword("")
        setNewPassword("")
        setProcessingAdminCreate(false)
        setProcessingPasswordChange(false)
        setMessage({ type: "info", body: "" })
        setMenuOptionSelected(menuOptions[0].value)
    }

    const handleAdminUserCreate = async (email: string) => {
        setProcessingAdminCreate(true)
        try {
            if (!email) {
                setMessage({ type: "error", body: "invalid email" })
                setProcessingAdminCreate(false)
                return
            }
            const currentUser = auth.currentUser
            if (!currentUser || !user) {
                throw new Error(`user isn't authenticated`)
            }
            const idToken = await currentUser.getIdToken(true)
            const orgId = user.orgId
            await authAdminUserCreate(email, idToken, orgId)
            setFetchAdminList(isOwnerOrSuper)
            setMessage({ type: "info", body: "successfully created admin user" })
        } catch (err: any) {
            console.error(err)
            setMessage({
                type: "error",
                body: err?.message ?? ErrorMessageGlobalFallback
            })
        }
        setProcessingAdminCreate(false)
    }

    const handlePasswordChangeSubmit = async (currentPassword: string, newPassword: string) => {
        setProcessingPasswordChange(true)
        try {
            if (!currentPassword) {
                setMessage({ type: "error", body: "please input a valid password" })
                setProcessingPasswordChange(false)
                return
            }
            if (!newPassword) {
                setMessage({ type: "error", body: "new password is invalid" })
                setProcessingPasswordChange(false)
                return
            }
            const currentUser = auth.currentUser
            if (!currentUser) {
                throw new Error(`user isn't authenticated`)
            }
            const email = currentUser.email
            if (!email) {
                throw new Error(`invalid user email`)
            }
            const credential = EmailAuthProvider.credential(email, currentPassword)
            const userCredential = await reauthenticateWithCredential(currentUser, credential)
            const reauthedUser = userCredential.user
            await updatePassword(reauthedUser, newPassword)
            setMessage({ type: "info", body: "password updated" })
        } catch (err) {
            console.error(err)
            setMessage({ type: "error", body: "an error occurred updating password. password may be incorrect" })
        }
        setCurrentPassword("")
        setNewPassword("")
        setProcessingPasswordChange(false)
    }

    const handleSettingsChangeSubmit = async () => {
        try {
            setProcessingSettingsChange(true)
            await dbOrgSimpleQuantityManagementSettingUpdate(org.id, serviceSelected, simpleQuantityManagement)
            await dbOrgMeasureUpdate(org.id, serviceSelected, optimizationSettings.measure)
        } catch (err: any) {
            console.error(err)
        } finally {
            setProcessingSettingsChange(false)
        }
    }

    const handleShadowOrgEnabled = () => {
        setShadowOrgEnabled(state => !state)
    }

    const handleSelectShadowOrg = (e: ChangeEvent<HTMLSelectElement>) => {
        const { value } = e.target
        const orgId = value
        const matchedOrg = superAllOrgs.find(o => o.id === orgId)
        if (matchedOrg) {
            setShadowOrg(matchedOrg)
        }
    }

    const handleSelectMenuItem = (val: AccountMenuOptionValue) => {
        setMenuOptionSelected(val)
        setMessage({ type: "info", body: "" })
    }

    const deleteAdminUser = async (uid: string, email: string | null) => {
        try {
            const confirmationMessage = typeof email === "string"
                ? `confirm deletion of user ${email}`
                : "confirm deletion"
            const confirmed = window.confirm(confirmationMessage)
            if (!confirmed) {
                return
            }
            const currentUser = auth.currentUser
            if (!currentUser || !user) {
                throw new Error(`user isn't authenticated`)
            }
            const idToken = await currentUser.getIdToken(true)
            const orgId = org.id
            await authDeleteAdmin(idToken, orgId, uid)
            setFetchAdminList(isOwnerOrSuper)
        } catch (err: any) {
            console.error(err)
            setMessage({
                type: "error",
                body: err?.message ?? "an unexpected error occurred. please contact support"
            })
        }
    }

    const renderTooltipContent = (content: string) => {
        return content.split("\n").map((paragraph, idx) => {
            const key = `${idx}-settings-tooltip`
            return (
                <div key={key}>
                    {paragraph}
                    <br />
                    <br />
                </div>
            )
        })
    }

    if (!user) {
        return null
    }

    return (
        <div>
            <input type="checkbox" id="account-settings-modal" className="modal-toggle" />
            <div className="modal">
                <div className="modal-box relative bg-dark max-w-[50rem] pt-10 pb-5 px-5 h-3/5 flex">
                    <div className="py-0 pr-5 pl-2 w-1/5 border-r-2 border-darker overflow-y-auto">
                        {menuOptions.map(o => {
                            if (!o.can_view.includes(user.role)) {
                                return null
                            }
                            if (o.value === "settings" && hidden.length > 0 && settingsHiddenOptions.every((o: any) => hidden.includes(o))) {
                                return null
                            }
                            return (
                                <div
                                    key={o.value}
                                    className={`mb-2 px-2 py-0.5 border-b border-dark hover:cursor-pointer rounded-md ${menuOptionSelected === o.value ? "border border-lighter" : "border border-dark"}`}
                                    onClick={() => handleSelectMenuItem(o.value)}
                                >
                                    {o.name}
                                </div>
                            )
                        })}
                    </div>
                    <label
                        htmlFor="account-settings-modal"
                        className="btn btn-sm btn-circle absolute right-2 top-2 bg-black border-none hover:bg-gray-900"
                        onClick={handleFieldsResetOnClose}
                    >
                        ✕
                    </label>
                    {
                        menuOptionSelected === "account" &&
                        (
                            <div className="w-4/5 px-8 overflow-y-auto">
                                <div className="flex items-center">
                                    <h3 className="text-lg font-bold">Account</h3>
                                </div>
                                <div className="overflow-y-auto min-h-[20rem] my-2">
                                    <div className="flex font-bold text-gray-500 mb-2 text-sm">
                                        <div className="text-gray-400 mr-2">id:</div>
                                        <div>{user?.orgId}</div>
                                    </div>
                                    <div className="flex font-bold text-gray-500 mb-2 text-sm">
                                        <div className="text-gray-400 mr-2">email: </div>
                                        <div>{user?.email}</div>
                                    </div>
                                    <div className="flex font-bold text-gray-500 mb-4 text-sm">
                                        <div className="text-gray-400 mr-2">role: </div>
                                        <div>{user?.role}</div>
                                    </div>
                                    <div className="w-full my-6" />
                                    <div className="w-full my-6" />
                                    <label className="block w-1/2 mt-2">Change password</label>
                                    <div className="flex flex-col w-2/3 my-2">
                                        <input
                                            type="password"
                                            className="mb-3 w-full bg-dark border border-gray-500 text-sm rounded-md block p-1.5 outline-none h-7 placeholder-gray-500"
                                            placeholder="current password"
                                            onChange={(e) => setCurrentPassword(e.target.value)}
                                            value={currentPassword}
                                        />
                                        <input
                                            type="password"
                                            className="mb-3 w-full bg-dark border border-gray-500 text-sm rounded-md block p-1.5 outline-none h-7 placeholder-gray-500"
                                            placeholder="new password"
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            value={newPassword}
                                        />
                                        <button
                                            className="p-1 mt-2 w-full rounded bg-black h-7 flex items-center justify-center text-gray-300 hover:bg-purple"
                                            onClick={() => handlePasswordChangeSubmit(currentPassword, newPassword)}
                                        >
                                            {
                                                processingPasswordChange
                                                    ? <span className="loading-spinner" />
                                                    : <span>submit</span>
                                            }
                                        </button>
                                    </div>
                                    {
                                        user?.role === "super" &&
                                        (
                                            <div>
                                                <div className="border-b border-b-gray-500 w-full my-6" />
                                                <label className="block w-1/2 mt-2">Shadow Org</label>
                                                <div className="flex flex-col w-2/3 my-2">
                                                    <div className="w-full mb-2">
                                                        <div className="flex items-center" onClick={handleShadowOrgEnabled}>
                                                            <input
                                                                type="checkbox"
                                                                checked={shadowOrgEnabled}
                                                                readOnly
                                                                className="accent-purple text-lg"
                                                            />
                                                            <label className="ml-2">enabled</label>
                                                        </div>
                                                    </div>
                                                    {
                                                        shadowOrgEnabled &&
                                                        (
                                                            <div className="text-sm font-bold">
                                                                <span className="mr-2">id:</span>
                                                                <span className="text-gray-500">{org.id}</span>
                                                            </div>
                                                        )
                                                    }
                                                    {
                                                        shadowOrgEnabled &&
                                                        (
                                                            <div>
                                                                <select
                                                                    className="p-1.5 rounded w-full bg-black outline-none text-xs sm:text-sm appearance-none mt-2"
                                                                    value={org?.id ?? ""}
                                                                    onChange={handleSelectShadowOrg}
                                                                >
                                                                    {superAllOrgs.map(o => {
                                                                        return (
                                                                            <option value={o.id} key={o.id}>{`${o.name} [${o.account_type}]`}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className={`${message.type === "info" ? "text-purple" : "text-red-error"}`}>{message.body}</div>
                            </div>
                        )
                    }
                    {
                        menuOptionSelected === "admins" &&
                        (
                            <div className="w-4/5 px-8 overflow-y-auto">
                                <div className="flex items-center mb-4">
                                    <h3 className="text-lg font-bold">Admin Users</h3>
                                </div>
                                <label className="block w-1/2">Add admin user</label>
                                <div className="flex items-center w-2/3 my-2">
                                    <input
                                        type="email"
                                        className="w-2/3 bg-dark border border-gray-500 text-sm rounded-md block p-1.5 outline-none h-7 placeholder-gray-500"
                                        placeholder="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                    <button
                                        className="ml-2 w-1/3 rounded bg-black h-7 flex items-center justify-center text-gray-300 hover:bg-purple"
                                        onClick={() => handleAdminUserCreate(email)}
                                    >
                                        {
                                            processingAdminCreate
                                                ? <span className="loading-spinner" />
                                                : <span className="material-symbols-outlined text-xl">add</span>
                                        }
                                    </button>
                                </div>
                                {
                                    adminUsersList.length > 0
                                        ? (

                                            <div className="relative overflow-x-auto mt-4">
                                                <table className="w-full text-sm text-left">
                                                    <thead className="text-xs uppercase">
                                                        <tr>
                                                            <th scope="col" className="px-6 py-3">
                                                                Email
                                                            </th>
                                                            <th scope="col" className="px-6 py-3">
                                                                Created
                                                            </th>
                                                            <th scope="col" className="px-6 py-3">
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {adminUsersList.map((u, idx) => {
                                                            return (
                                                                <tr key={u.email} className={`${idx % 2 === 0 ? "bg-black" : ""}`}>
                                                                    <th scope="row" className="px-6 py-4 font-medium whitespace-nowrap">
                                                                        {u.email}
                                                                    </th>
                                                                    <td className="px-6 py-4">
                                                                        {u.metadata?.creationTime ?? "-"}
                                                                    </td>
                                                                    <td>
                                                                        <span
                                                                            className="p-1 material-symbols-outlined text-red-error opacity-70 hover:cursor-pointer"
                                                                            onClick={() => deleteAdminUser(u.uid, u.email)}
                                                                        >
                                                                            delete
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )
                                        : (
                                            <div className="mt-4">
                                                No admin users found
                                            </div>
                                        )
                                }
                                <div className={`mt-4 ${message.type === "info" ? "text-purple" : "text-red-error"}`}>{message.body}</div>
                            </div>
                        )
                    }
                    {
                        menuOptionSelected === "settings" &&
                        (
                            <div className="w-4/5 px-8 overflow-y-auto">
                                <div className="flex items-center">
                                    <h3 className="text-lg font-bold">
                                        Settings
                                        <span className="text-gray-500">{` - ${serviceSelected === "route_optimization" ? "Route Optimization" : "Auto Dispatch"}`}</span>
                                    </h3>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full mb-8">
                                    {
                                        !hidden.includes("measure") &&
                                        <div className="sm:w-1/2">
                                            <div className="flex items-center mt-4 text-gray-500">
                                                <label className="block">Measure</label>
                                                <div
                                                    className="cursor-pointer ml-2 flex items-center"
                                                    onMouseEnter={() => {
                                                        setSettingsTooltip(tooltipMeasure)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setSettingsTooltip("")
                                                    }}
                                                >
                                                    <span className="material-symbols-outlined text-xl">
                                                        info
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <select
                                                    className="px-2 py-1 bg-dark border-b border-gray-500 outline-none appearance-none w-2/3"
                                                    onChange={(e) => {
                                                        optActions.updateMeasure(optType, e.target.value as DbOptMeasure)
                                                    }}
                                                    value={optimizationSettings?.measure}
                                                >
                                                    {optMeasures.map(m => {
                                                        let measure = m as string
                                                        if (m === "streets") {
                                                            measure = "street"
                                                        }
                                                        if (m === "default") {
                                                            measure = "haversine"
                                                        }
                                                        return <option key={m} value={m}>{measure}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                    {
                                        !hidden.includes("simple_quantity_management") &&
                                        <div className="sm:w-1/2">
                                            <div className="flex items-center mt-4 text-gray-500">
                                                <label className="block">Capacity Management</label>
                                                <div
                                                    className="cursor-pointer ml-2 flex items-center"
                                                    onMouseEnter={() => {
                                                        setSettingsTooltip(tooltipQuantityManagement)
                                                    }}
                                                    onMouseLeave={() => {
                                                        setSettingsTooltip("")
                                                    }}
                                                >
                                                    <span className="material-symbols-outlined text-xl">
                                                        info
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="flex items-center">
                                                <select
                                                    className="px-2 py-1 bg-dark border-b border-gray-500 outline-none appearance-none w-2/3"
                                                    onChange={(e) => {
                                                        const simple = e.target.value === "simple"
                                                        optActions.updateSimpleQuantityManagement(optType, simple)
                                                    }}
                                                    value={simpleQuantityManagement ? "simple" : "default"}
                                                >
                                                    {quantityManagementOptions.map(opt => {
                                                        return <option key={opt} value={opt}>{opt}</option>
                                                    })}
                                                </select>
                                            </div>
                                        </div>
                                    }
                                </div>
                                <button
                                    className="p-1 mt-2 w-full rounded bg-black h-7 flex items-center justify-center text-gray-300 hover:bg-purple"
                                    onClick={() => handleSettingsChangeSubmit()}
                                >
                                    {
                                        processingSettingsChange
                                            ? <span className="loading-spinner" />
                                            : <span>save</span>
                                    }
                                </button>
                                <div className="mt-3 text-gray-500 text-xs sm:text-base">
                                    {renderTooltipContent(settingsTooltip)}
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}
