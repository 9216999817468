import config from "config"

// constants
import { ErrorMessageGlobalFallback } from "utils/constants"

// types
import type { ServerResponse } from "../../../types/server"
import type {
    RoRequestParams,
    RoRunResultResponseData
} from "../../../types/services/ro"

export async function runRouteOptimization(idToken: string, orgId: string, body: RoRequestParams, setter: (el: any) => void) {
    const headers = {
        "id-token": idToken,
        "org-id": orgId,
        "Content-Type": "application/json"
    }
    const res = await fetch(config.serverURL + "/ro/run", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    })

    const resObj = await res.json() as ServerResponse
    if (res.status !== 200) {
        const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
        throw new Error(errMessage)
    }

    setter(resObj.data as RoRunResultResponseData)
}

export async function applyRunResult(idToken: string, orgId: string, body: RoRunResultResponseData) {
    const headers = {
        "id-token": idToken,
        "org-id": orgId,
        "Content-Type": "application/json"
    }
    const res = await fetch(config.serverURL + "/ro/apply_run_result", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    })
    if (res.status !== 200) {
        const resObj = await res.json() as ServerResponse
        const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
        throw new Error(errMessage)
    }
}
