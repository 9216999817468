import { useState } from "react"
// stores
import { useUserStore } from "stores/global"
import { useOptStore } from "stores/opt"

// constants
import {
    vehicleTypes,
    vehicleSpeedUnitTypes,
    timezones
} from "utils/constants"

// types
import type { DbOrg } from "@/../../../types/lib/db"
import type {
    OnfAllTeamsResponseData,
    OnfAllWorkersResponseData,
    OnfHubsResponseData
} from "@/../../../types/services/onfleet"

type ConfigurationSettingsProps = {
    hubs: OnfHubsResponseData,
    teams: OnfAllTeamsResponseData,
    workers: OnfAllWorkersResponseData,
    handleOptimizationPanelToggle: () => void,
    handleSummaryDetailsPanelActivate: () => void,
    org: DbOrg
}

export default function ConfigurationSettings({
    hubs,
    teams,
    workers,
    handleOptimizationPanelToggle,
    handleSummaryDetailsPanelActivate,
    org
}: ConfigurationSettingsProps) {
    const optimizationSettings = useOptStore(state => state.ro.state.optimization_settings)
    const userTimezone = useUserStore(state => state.user?.timezone)
    const optActions = useOptStore(state => state.actions)
    const roState = useOptStore(state => state.ro.state)
    const roActions = useOptStore(state => state.ro.actions)

    const [driverSearch, setDriverSearch] = useState("")

    const workersSelectedPerTeam = roState.workers_selected_per_team
    const allWorkersSelected = roState.workers_selected_all
    const forDate = roState.for_date
    const routeStart = roState.start_at
    const routeEnd = roState.end_at
    const timezone = roState.timezone

    const intHours = Array.from({ length: 12 }, (_, i) => i + 1)
    const intMinutes = Array.from({ length: 60 }, (_, i) => i).filter(m => m % 5 === 0)

    const hiddenRoElements = org.services.route_optimization.hidden

    return (
        <div className="flex flex-col items-center w-2/3 text-sm sm:text-base ml-8 border-l-2 border-dark">
            <div className="w-full flex items-center p-2">
                <span
                    className="material-symbols-outlined hover:cursor-pointer text-3xl"
                    onClick={handleOptimizationPanelToggle}
                >
                    close
                </span>
            </div>
            <div className="w-full h-full flex flex-col items-center px-6 overflow-y-auto">
                <div className="text-lg">
                    Optimize Tasks
                </div>
                {
                    userTimezone &&
                    (
                        <div className="w-full sm:w-5/6 mt-6 flex flex-col md:flex-row items-start md:items-center">
                            <input
                                type="date"
                                name="for_date"
                                value={forDate}
                                className="text-gray-900 bg-gray-300 rounded outline-none h-8 p-0.5 w-30 min-h-fit"
                                onChange={(e) => optActions.updateForDate("ro", e.target.value)}
                            />

                            <select
                                className="w-64 text-sm rounded bg-darker outline-none appearance-none md:ml-4 mt-2 md:mt-0 h-8 py-1 px-2"
                                value={timezone}
                                onChange={(e) => {
                                    optActions.updateTimezone("ro", e.target.value)
                                }}
                            >
                                {timezones.map(tz => {
                                    return (
                                        <option value={tz} key={tz}>
                                            {tz}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    )
                }
                <div className="mt-4 w-full sm:w-5/6">Drivers</div>
                <div className="w-full sm:w-5/6 outline-none appearance-none flex text-gray-500 rounded">
                    <span className="material-symbols-outlined text-xl">
                        search
                    </span>
                    <input
                        className="w-full bg-black outline-none appearance-none px-2 placeholder:text-gray-500 text-sm"
                        placeholder="Find driver"
                        type="text"
                        onChange={(e) => {
                            setDriverSearch(e.target.value)
                        }}
                    />
                </div>
                <div className="mt-2 p-4 rounded border border-dark w-full sm:w-5/6 max-h-52 overflow-y-auto text-sm min-h-[13rem]">
                    {teams.map(team => {
                        const teamWorkers = team.workers as string[]
                        if (team.workers?.length === 0) {
                            return null
                        }
                        const teamWorkersSelected = workersSelectedPerTeam[team.id]?.workers_selected ?? []
                        const areAllTeamWorkersSelectedWithinTeam = teamWorkersSelected.length > 0
                            && teamWorkers.every((w: string) => teamWorkersSelected.includes(w))
                        const areAllTeamWorkersSelectedAcrossTeams = allWorkersSelected.length > 0
                            && teamWorkers.every((w: string) => allWorkersSelected.includes(w))
                        const workersSelectedInTeamCount = allWorkersSelected.reduce((acc, workerId) => {
                            if (teamWorkers.includes(workerId)) {
                                return acc + 1
                            }
                            return acc
                        }, 0)

                        return (
                            <div key={team.id}>
                                <div
                                    className="bg-darker py-1 px-3 mb-1 flex items-center hover:cursor-pointer border border-darker"
                                    onClick={() => roActions.updateWorkersSelected({
                                        team: team.id,
                                        teamChecked: !areAllTeamWorkersSelectedAcrossTeams
                                    })}
                                >
                                    <input
                                        type="checkbox"
                                        className="hover:cursor-pointer accent-purple"
                                        checked={areAllTeamWorkersSelectedAcrossTeams}
                                        disabled={
                                            areAllTeamWorkersSelectedAcrossTeams
                                            && !areAllTeamWorkersSelectedWithinTeam
                                        }
                                        readOnly={true}
                                    />
                                    <label className="ml-2 hover:cursor-pointer">{team.name}</label>
                                    <label className="ml-4 text-gray-500">{`${workersSelectedInTeamCount}/${teamWorkers.length}`}</label>
                                </div>
                                {teamWorkers.map(workerId => {
                                    const workerName = workers[workerId]?.name
                                    if (!workerName) {
                                        return null
                                    }
                                    if (driverSearch && !workerName.toLowerCase().includes(driverSearch.toLowerCase())) {
                                        return null
                                    }
                                    const isWorkerSelectedWithinTeam = teamWorkersSelected.includes(workerId)
                                    const isWorkerSelectedAcrossTeams = allWorkersSelected.includes(workerId)
                                    return (
                                        <div
                                            key={workerId}
                                            className="py-1 px-3 mb-1 flex items-center hover:cursor-pointer border border-black hover:border-darker"
                                            onClick={() => roActions.updateWorkersSelected({
                                                team: team.id,
                                                worker: workerId,
                                                workerChecked: !isWorkerSelectedAcrossTeams
                                            })}
                                        >
                                            <input
                                                type="checkbox"
                                                className="hover:cursor-pointer accent-purple"
                                                checked={isWorkerSelectedAcrossTeams}
                                                disabled={
                                                    isWorkerSelectedAcrossTeams
                                                    && !isWorkerSelectedWithinTeam
                                                }
                                                readOnly={true}
                                            />
                                            <label
                                                className="ml-2 hover:cursor-pointer"
                                            >
                                                {workerName}
                                            </label>
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
                <div className="w-full border-b border-dark py-4" />
                <div className="mt-4 w-full sm:w-5/6 flex justify-center sm:justify-start">Optimization Settings</div>
                <div className="flex w-full sm:w-5/6 flex-col sm:flex-row items-center sm:items-start">
                    <div className="w-full sm:w-1/2 sm:mr-2">
                        <div className="mt-1">
                            <label className="block text-sm mb-1.5 mt-2">Maximum number of tasks per route</label>
                            <div className="flex items-center">
                                <input
                                    type="number"
                                    name="route_max_tasks"
                                    min="0"
                                    step="1"
                                    className="w-1/3 bg-gray-300 border border-gray-500 text-gray-900 text-sm rounded-md block p-0.5 outline-none"
                                    onChange={(e) => {
                                        optActions.updateOptimizationSettingsNumerical("ro", "route_max_tasks", e.target.value)
                                    }}
                                    value={optimizationSettings.route_max_tasks}
                                />
                                <div className="w-1/3 ml-2 text-xs">tasks</div>
                            </div>
                            <label className="block mb-1.5 text-sm mt-2">Service Time per task</label>
                            <div className="flex items-center">
                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    name="task_service_time"
                                    className="w-1/3 bg-gray-300 border border-gray-500 text-gray-900 text-sm rounded-md block p-0.5 outline-none"
                                    onChange={(e) => {
                                        optActions.updateOptimizationSettingsNumerical("ro", "task_service_time", e.target.value)
                                    }}
                                    value={optimizationSettings.task_service_time}
                                />
                                <div className="w-1/3 ml-2 text-xs">minutes</div>
                            </div>
                            {
                                !hiddenRoElements.includes("task_max_delay") &&
                                <div>
                                    <label className="block mb-1.5 text-sm mt-2">Maximum allowed delay per task</label>
                                    <div className="flex items-center">
                                        <input
                                            type="number"
                                            min="0"
                                            step="1"
                                            name="task_max_delay"
                                            className="w-1/3 bg-gray-300 border border-gray-500 text-gray-900 text-sm rounded-md block p-0.5 outline-none"
                                            onChange={(e) => {
                                                optActions.updateOptimizationSettingsNumerical("ro", "task_max_delay", e.target.value)
                                            }}
                                            value={optimizationSettings.task_max_delay}
                                        />
                                        <div className="w-1/3 ml-2 text-xs">minutes</div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="w-full sm:w-1/2 sm:ml-2">
                        <div className="mt-1">
                            <label className="block mb-1.5 text-sm mt-2">Default driver vehicle capacity</label>
                            <div className="flex items-center">
                                <input
                                    type="number"
                                    min="0"
                                    step="1"
                                    name="worker_capacity"
                                    className="w-1/3 bg-gray-300 border border-gray-500 text-gray-900 text-sm rounded-md block p-0.5 outline-none"
                                    onChange={(e) => {
                                        optActions.updateOptimizationSettingsNumerical("ro", "worker_capacity", e.target.value)
                                    }}
                                    value={optimizationSettings.worker_capacity}
                                />
                            </div>
                            {/* <label className="block mb-1.5 text-sm mt-2">Default task quantity</label> */}
                            {/* <div className="flex items-center"> */}
                            {/*     <input */}
                            {/*         type="number" */}
                            {/*         name="taskQuantityDefault" */}
                            {/*         className="w-1/3 bg-gray-300 border border-gray-500 text-gray-900 text-sm rounded-md block p-0.5 outline-none" */}
                            {/*         onChange={handleOptimizationSettingsChange} */}
                            {/*         value={optimizationSettings.taskQuantityDefault} */}
                            {/*     /> */}
                            {/* </div> */}
                            <label className="block mb-1.5 text-sm mt-2">Default driver schedule</label>
                            <div className="flex flex-col justify-center text-sm">
                                <div>
                                    <select
                                        className="p-1 rounded-md bg-dark outline-none appearance-none"
                                        onChange={(e) => {
                                            optActions.updateOptimizationSettingsWorkerSchedule(
                                                "ro",
                                                "shift_start",
                                                "hour",
                                                e.target.value
                                            )
                                        }}
                                        value={optimizationSettings.worker_schedule?.shift_start?.hour}
                                        name="hour"
                                    >
                                        {intHours.map(h => {
                                            return <option value={h} key={h}>{h.toString()}</option>
                                        })}
                                    </select>
                                    <span className="mx-2">:</span>
                                    <select
                                        className="p-1 rounded-md bg-dark outline-none appearance-none"
                                        onChange={(e) => {
                                            optActions.updateOptimizationSettingsWorkerSchedule(
                                                "ro",
                                                "shift_start",
                                                "minute",
                                                e.target.value
                                            )
                                        }}
                                        value={optimizationSettings.worker_schedule?.shift_start?.minute}
                                        name="minute"
                                    >
                                        {intMinutes.map(m => {
                                            return <option value={m} key={m}>{m.toString().length === 1 ? `0${m}` : `${m}`}</option>
                                        })}
                                    </select>
                                    <select
                                        className="p-1 rounded-md bg-dark outline-none sm:mx-1 mt-1 sm:mt-0 appearance-none"
                                        onChange={(e) => {
                                            optActions.updateOptimizationSettingsWorkerSchedule(
                                                "ro",
                                                "shift_start",
                                                "modifier",
                                                e.target.value
                                            )
                                        }}
                                        value={optimizationSettings.worker_schedule?.shift_start?.modifier}
                                        name="modifier"
                                    >
                                        <option value="PM">PM</option>
                                        <option value="AM">AM</option>
                                    </select>
                                    <span className="mx-2 text-sm">shift start</span>
                                </div>
                                <div>
                                    <select
                                        className="p-1 rounded-md bg-dark outline-none mt-1 appearance-none"
                                        onChange={(e) => {
                                            optActions.updateOptimizationSettingsWorkerSchedule(
                                                "ro",
                                                "shift_end",
                                                "hour",
                                                e.target.value
                                            )
                                        }}
                                        value={optimizationSettings.worker_schedule?.shift_end?.hour}
                                        name="hour"
                                    >
                                        {intHours.map(h => {
                                            return <option value={h} key={h}>{h.toString()}</option>
                                        })}
                                    </select>
                                    <span className="mx-2">:</span>
                                    <select
                                        className="p-1 rounded-md bg-dark outline-none appearance-none"
                                        onChange={(e) => {
                                            optActions.updateOptimizationSettingsWorkerSchedule(
                                                "ro",
                                                "shift_end",
                                                "minute",
                                                e.target.value
                                            )
                                        }}
                                        value={optimizationSettings.worker_schedule?.shift_end?.minute}
                                        name="minute"
                                    >
                                        {intMinutes.map(m => {
                                            return <option value={m} key={m}>{m.toString().length === 1 ? `0${m}` : `${m}`}</option>
                                        })}
                                    </select>
                                    <select
                                        className="p-1 rounded-md bg-dark outline-none sm:mx-1 mt-1 sm:mt-0 appearance-none"
                                        onChange={(e) => {
                                            optActions.updateOptimizationSettingsWorkerSchedule(
                                                "ro",
                                                "shift_end",
                                                "modifier",
                                                e.target.value
                                            )
                                        }}
                                        value={optimizationSettings.worker_schedule?.shift_end?.modifier}
                                        name="modifier"
                                    >
                                        <option value="PM">PM</option>
                                        <option value="AM">AM</option>
                                    </select>
                                    <span className="mx-2 text-sm">shift end</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    !hiddenRoElements.includes("worker_vehicle_speed") &&
                    <div className="w-full border-b border-dark py-4" />
                }
                {
                    !hiddenRoElements.includes("worker_vehicle_speed") &&
                    (
                        <div className="w-full sm:w-5/6">
                            <div className="w-full mt-4">
                                <div className="flex flex-col">
                                    <label>Vehicle Speeds</label>
                                    <label className="text-sm mt-1 text-gray-500">* speeds will be applied based on Onfleet worker vehicle type *</label>
                                </div>
                                <div className="flex flex-col sm:flex-row w-full mt-3 overflow-x-auto">
                                    {vehicleTypes.map((vehicleType, index, arr) => {
                                        let icon = null
                                        switch (vehicleType) {
                                            case "car":
                                                icon = (
                                                    <span className="material-symbols-outlined">
                                                        directions_car
                                                    </span>
                                                )
                                                break
                                            case "truck":
                                                icon = (
                                                    <span className="material-symbols-outlined">
                                                        local_shipping
                                                    </span>
                                                )
                                                break
                                            case "motorcycle":
                                                icon = (
                                                    <span className="material-symbols-outlined">
                                                        two_wheeler
                                                    </span>
                                                )
                                                break
                                            case "bicycle":
                                                icon = (
                                                    <span className="material-symbols-outlined">
                                                        directions_bike
                                                    </span>
                                                )
                                                break
                                            case "walking":
                                                icon = (
                                                    <span className="material-symbols-outlined">
                                                        directions_walk
                                                    </span>
                                                )
                                                break
                                            default:
                                                break
                                        }
                                        return (
                                            <div className={`w-full sm:w-1/4 ${index !== arr.length - 1 ? "sm:mr-2" : ""}`} key={vehicleType}>
                                                <div>{icon !== null ? icon : vehicleType}</div>
                                                <div className="flex mt-1 mb-5 sm:mb-0">
                                                    <input
                                                        type="number"
                                                        min="0"
                                                        step="1"
                                                        name="value"
                                                        className="w-40 sm:w-14 bg-black border border-dark rounded-md text-sm block p-1 outline-none"
                                                        onChange={(e) => {
                                                            optActions.updateOptimizationSettingsVehicleSpeed(
                                                                "ro",
                                                                vehicleType,
                                                                "value",
                                                                e.target.value
                                                            )
                                                        }}
                                                        value={optimizationSettings.worker_vehicle_speed[vehicleType].value}
                                                    />
                                                    <select
                                                        className="w-10 ml-2 p-0.5 rounded-md bg-dark outline-none appearance-none text-xs sm:text-sm"
                                                        onChange={(e) => {
                                                            optActions.updateOptimizationSettingsVehicleSpeed(
                                                                "ro",
                                                                vehicleType,
                                                                "unit",
                                                                e.target.value
                                                            )
                                                        }}
                                                        name="unit"
                                                        value={optimizationSettings.worker_vehicle_speed[vehicleType].unit}
                                                    >
                                                        {vehicleSpeedUnitTypes.map(unit => {
                                                            return <option value={unit} key={unit}>{unit}</option>
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    )
                }
                <div className="w-full border-b border-dark py-4" />
                <div className="mt-4 w-full sm:w-5/6">Choose where the routes start and end</div>
                <div className="w-full sm:w-5/6 mt-1">
                    <div className="flex items-center mt-2">
                        <div className="w-1/3 text-sm mr-4">Start from</div>
                        <select
                            className="w-1/2 bg-gray-300 text-gray-900 text-sm rounded-md block p-1 outline-none"
                            onChange={(e) => roActions.updateRouteStartEnd("start_at", e.target.value)}
                            value={routeStart}
                        >
                            <optgroup label="Generic">
                                <option value="worker_locations">Worker locations</option>
                                <option value="worker_addresses">Worker addresses</option>
                            </optgroup>
                            <optgroup label="Hub">
                                {Object.entries(hubs).map(([hubId, hub]) => {
                                    return <option value={hubId} key={hubId}>{hub.name}</option>
                                })}
                            </optgroup>
                        </select>
                    </div>
                    <div className="flex items-center mt-2">
                        <div className="w-1/3 text-sm mr-4">End at</div>
                        <select
                            className="w-1/2 bg-gray-300 text-gray-900 text-sm rounded-md block p-1 outline-none"
                            onChange={(e) => roActions.updateRouteStartEnd("end_at", e.target.value)}
                            value={routeEnd}
                        >
                            <optgroup label="Generic">
                                <option value="worker_locations">Worker locations</option>
                                <option value="worker_addresses">Worker addresses</option>
                            </optgroup>
                            <optgroup label="Hub">
                                {Object.entries(hubs).map(([hubId, hub]) => {
                                    return <option value={hubId} key={hubId}>{hub.name}</option>
                                })}
                            </optgroup>
                        </select>
                    </div>
                </div>
                <div className="w-full border-b border-dark py-4" />
                <div
                    className="flex items-center justify-center bg-purple text-whitish hover:bg-purple-light p-1 rounded w-5/6 cursor-pointer mt-10 mb-5"
                    onClick={handleSummaryDetailsPanelActivate}
                >
                    Proceed
                </div>
            </div>
        </div>
    )
}
