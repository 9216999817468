import { useState } from "react"
import { DateTime } from "luxon"
import { auth } from "fb"

// components
import ConfigurationSettings from "./components/ConfigurationSettings"
import PreRunReview from "./components/PreRunReview"
import Processing from "./components/Processing"
import RunResult from "./components/RunResult"

// constants
import { ErrorMessageGlobalFallback } from "utils/constants"

// ro
import {
    runRouteOptimization,
    applyRunResult
} from "calls/ro"

// stores
import { useUserStore } from "stores/global"
import { useOptStore } from "stores/opt"

// auth
import { authUpdateUserTimezone } from "calls/auth"

// db
import { dbOrgRouteOptimizationSettingsUpdate } from "calls/db"

// types
import type {
    Message
} from "types"
import type {
    OnfHubsResponseData,
    OnfAllTeamsResponseData,
    OnfAllWorkersResponseData,
    OnfTasksListResponseData
} from "@/../../../types/services/onfleet"
import {
    DbOrg,
    DbRoStartEndOption
} from "@/../../../types/lib/db"
import type {
    RoRequestParams,
    RoRunResultResponseData
} from "@/../../../types/services/ro"
import type {
    OnfleetWorker
} from "@onfleet/node-onfleet/Resources/Workers"

type OptimizationPanelProps = {
    teams: OnfAllTeamsResponseData,
    workers: OnfAllWorkersResponseData,
    hubs: OnfHubsResponseData,
    handleOptimizationPanelToggle: () => void,
    setGlobalMessage: (msg: Message) => void,
    tasks: OnfTasksListResponseData,
    taskIdsSelected: string[],
    setTaskIdsSelected: (taskIds: string[]) => void,
    org: DbOrg
}

type DriversSelectedByTeam = {
    [teamId: string]: {
        optimize: boolean,
        workerData: OnfleetWorker
    }[]
}

export type DriverSelections = {
    allDriversSelectedRef: Set<string>,
    driversSelectedByTeam: DriversSelectedByTeam
}

export type RouteStartEnd = {
    start: DbRoStartEndOption,
    end: DbRoStartEndOption
}

export default function OptimizationPanel({
    teams = [],
    workers = {},
    hubs = {},
    handleOptimizationPanelToggle,
    setGlobalMessage,
    tasks,
    taskIdsSelected = [],
    setTaskIdsSelected,
    org
}: OptimizationPanelProps) {
    const user = useUserStore(state => state.user)
    const setUserTimezone = useUserStore(state => state.setUserTimezone)
    const optimizationSettings = useOptStore(state => state.ro.state.optimization_settings)

    const roStore = useOptStore(state => state.ro)
    const roState = roStore.state
    const roActions = roStore.actions

    const [processingOptimization, setProcessingOptimization] = useState(false)
    /* const [percentageProcessed, setPercentageProcessed] = useState(0) */
    const [processingMessage, setProcessingMessage] = useState<Message>({ type: "info", body: "" })
    const [optimizationResult, setOptimizationResult] = useState<RoRunResultResponseData | null>(null)
    const [processingWorkerTaskUpdates, setProcessingWorkerTaskUpdates] = useState(false)
    const [workerTaskAssignmentStatus, setWorkerTaskAssignmentStatus] = useState<"none" | "failed" | "succeeded">("none")
    const [erroredWorkers, setErroredWorkers] = useState([])

    const [summaryDetailsPanelActive, setSummaryDetailsPanelActive] = useState(false)

    const handleProcessOptimizationSubmit = async () => {
        try {
            if (taskIdsSelected.length < 2) {
                setGlobalMessage({ type: "error", body: "at least two tasks must be selected to optimize" })
                return
            }

            setProcessingOptimization(true)
            setProcessingMessage({ type: "info", body: "Processing" })


            const orgId = org.id
            await dbOrgRouteOptimizationSettingsUpdate(orgId, optimizationSettings)

            const requestBody: RoRequestParams = {
                tasks: taskIdsSelected,
                workers: roState.workers_selected_all,
                start_at: roState.start_at,
                end_at: roState.end_at,
                for_date: roState.for_date,
                timezone: roState.timezone
            }

            /* const sleep = (millis: number) => { */
            /*     return new Promise((resolve) => { */
            /*         setTimeout(() => { */
            /*             resolve(true) */
            /*         }, millis) */
            /*     }) */
            /* } */

            const currentUser = auth.currentUser
            if (!currentUser) {
                throw new Error(`user isn't authenticated`)
            }

            const idToken = await currentUser.getIdToken(true)

            if (user && roState.timezone && user.timezone !== roState.timezone) {
                const updateUserTimezone = async function() {
                    await authUpdateUserTimezone(idToken, orgId, roState.timezone)
                    setUserTimezone(roState.timezone)
                }
                updateUserTimezone()
            }

            const setter = function(optResult: RoRunResultResponseData) {
                setOptimizationResult(optResult)
                setProcessingMessage({ type: "info", body: "Optimization Complete" })
            }

            const runProm = runRouteOptimization(idToken, orgId, requestBody, setter)

            /* const progressStep = Math.round(100 / optimizationSettings.solver_duration) */

            /* for (let i = 0; i < optimizationSettings.solver_duration; i++) { */
            /*     if (percentageProcessed + progressStep >= 90) { */
            /*         break */
            /*     } */
            /*     setPercentageProcessed(state => state + progressStep) */
            /*     await sleep(1000) */
            /* } */

            await runProm
            /* setPercentageProcessed(100) */
            setProcessingOptimization(false)
            /* setTimeout(() => { */
            /*     setPercentageProcessed(0) */
            /* }, 2000) */
        } catch (err: any) {
            console.error(err)
            setProcessingMessage({ type: "error", body: err.message ?? ErrorMessageGlobalFallback })
        }
    }

    const handleSummaryDetailsPanelActivate = () => {
        if (taskIdsSelected.length < 2) {
            setGlobalMessage({ type: "error", body: "at least two tasks must be selected to optimize" })
            return
        }
        if (roState.workers_selected_all.length === 0) {
            setGlobalMessage({ type: "error", body: "must select at least one driver" })
            return
        }
        const validSchedule = () => {
            const currentDateFormatted = DateTime.now().toUTC().toFormat("yyyy-MM-dd")
            const shiftStart = optimizationSettings.worker_schedule.shift_start
            const shiftEnd = optimizationSettings.worker_schedule.shift_end
            const shiftStartFormatted = `${shiftStart.hour.toString().padStart(2, "0")}:${shiftStart.minute.toString().padStart(2, "0")} ${shiftStart.modifier}`
            const shiftEndFormatted = `${shiftEnd.hour.toString().padStart(2, "0")}:${shiftEnd.minute.toString().padStart(2, "0")} ${shiftEnd.modifier}`
            const dateStrShiftStart = `${currentDateFormatted} ${shiftStartFormatted}`
            const dateStrShiftEnd = `${currentDateFormatted} ${shiftEndFormatted}`
            const dateTimeShiftStart = DateTime.fromFormat(dateStrShiftStart, "yyyy-MM-dd hh:mm a")
            const dateTimeShiftEnd = DateTime.fromFormat(dateStrShiftEnd, "yyyy-MM-dd hh:mm a")
            return dateTimeShiftEnd > dateTimeShiftStart
        }
        if (!validSchedule()) {
            setGlobalMessage({ type: "error", body: "worker schedule shift end must be later than shift start" })
            return
        }
        setSummaryDetailsPanelActive(true)
        setGlobalMessage({ type: "info", body: "" })
    }

    const handleCloseProcessingPanel = () => {
        setProcessingOptimization(false)
        setProcessingMessage({ type: "info", body: "" })
        /* setPercentageProcessed(0) */
    }

    const handleCloseResultsPanel = () => {
        setOptimizationResult(null)
        setProcessingOptimization(false)
        setProcessingMessage({ type: "info", body: "" })
        /* setPercentageProcessed(0) */
    }

    const handleReturnToConfigurationSettings = () => {
        setProcessingOptimization(false)
        setProcessingMessage({ type: "info", body: "" })
        setOptimizationResult(null)
        /* setPercentageProcessed(0) */
        setProcessingWorkerTaskUpdates(false)
        setWorkerTaskAssignmentStatus("none")
        setErroredWorkers([])
        setSummaryDetailsPanelActive(false)
        setTaskIdsSelected([])
    }

    const handleOptimizationResultsAccept = async (optimizationResult: RoRunResultResponseData) => {
        setProcessingWorkerTaskUpdates(true)
        try {
            const currentUser = auth.currentUser
            if (!currentUser) {
                throw new Error(`user isn't authenticated`)
            }
            const idToken = await currentUser.getIdToken(true)
            const orgId = org.id
            await applyRunResult(idToken, orgId, optimizationResult)
            setWorkerTaskAssignmentStatus("succeeded")
        } catch (err: any) {
            console.error(err)
            setWorkerTaskAssignmentStatus("failed")
        } finally {
            roActions.reset()
            setProcessingWorkerTaskUpdates(false)
            roActions.triggerFetches({
                tasks: true,
                teams: true,
                workers: true
            })
            setTaskIdsSelected([])
        }
    }

    if (optimizationResult) {
        return (
            <RunResult
                optimizationResult={optimizationResult}
                handleCloseResultsPanel={handleCloseResultsPanel}
                processingMessage={processingMessage}
                workers={workers}
                workerTaskAssignmentStatus={workerTaskAssignmentStatus}
                handleReturnToConfigurationSettings={handleReturnToConfigurationSettings}
                processingWorkerTaskUpdates={processingWorkerTaskUpdates}
                handleOptimizationResultsAccept={handleOptimizationResultsAccept}
                erroredWorkers={erroredWorkers}
            />
        )
    }

    if (processingOptimization) {
        return (
            <Processing
                handleCloseProcessingPanel={handleCloseProcessingPanel}
                processingMessage={processingMessage}
            /* percentageProcessed={percentageProcessed} */
            />
        )
    }

    if (summaryDetailsPanelActive) {
        return (
            <PreRunReview
                tasks={tasks}
                taskIdsSelected={taskIdsSelected}
                setSummaryDetailsPanelActive={setSummaryDetailsPanelActive}
                handleProcessOptimizationSubmit={handleProcessOptimizationSubmit}
            />
        )
    }

    return (
        <ConfigurationSettings
            hubs={hubs}
            teams={teams}
            workers={workers}
            handleOptimizationPanelToggle={handleOptimizationPanelToggle}
            handleSummaryDetailsPanelActivate={handleSummaryDetailsPanelActivate}
            org={org}
        />
    )
}
