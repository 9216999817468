import config from "config"
import type { ParamDetails } from "shared/components/ApiDoc"

export const apiDocExampleReqBody = {
    team_id: "XfLmTh86TeMpr5aILh4A",
    /* for_date: "2022-10-31", */
    /* omit_flagged_tasks: true, */
    /* offline_workers_enabled: false, */
    /* global_unassigned_enabled: true, */
    /* tasks_excluded: [], */
    /* workers_excluded: [], */
    /* allow_unassigned: true, */
    /* worker_backlog_lock: false, */
    /* worker_backlog_order_lock: false, */
    /* start_at: "team_hub", */
    /* end_at: "team_hub", */
    /* timezone: "America/Los_Angeles" */
}

export const apiUrl = config.serverURL + "/ad/run"

export const apiMethod = "POST"

export const apiDocReqParams: ParamDetails[] = [
    {
        name: "team_id",
        required: true,
        type: "string",
        default: null,
        description: "Team to optimize across."
    },
    {
        name: "teams",
        required: true,
        type: "[ ]string",
        default: null,
        description: "Required IF team_id isn't provided. Allows multiple teams to be optimized for in a single request. team_id and teams cannot be both be specified, pick one or the other."
    },
    {
        name: "for_date",
        required: false,
        type: "string",
        default: `current date "yyyy-mm-dd"`,
        description: `Date to optimize for (e.g. "2022-10-31")`
    },
    {
        name: "omit_flagged_tasks",
        required: false,
        type: "boolean",
        default: "true",
        description: `Tasks with metadata object name "omit_auto_dispatch", type "boolean", and value true will not be included in optimization.`
    },
    {
        name: "global_unassigned_enabled",
        required: false,
        type: "boolean",
        default: "false",
        description: "If true tasks assigned to global organization container will be included in optimization."
    },
    {
        name: "tasks_excluded",
        required: false,
        type: "[  ]string",
        default: "[  ]",
        description: "Task IDs to exclude from optimization. If empty all tasks from selected team (and global unassigned tasks if enabled) will be included."
    },
    {
        name: "workers_excluded",
        required: false,
        type: "[  ]string",
        default: "[  ]",
        description: "Worker IDs to exclude from optimization. If empty all workers in specified team will be included."
    },
    {
        name: "allow_unassigned",
        required: false,
        type: "boolean",
        default: "true",
        description: "If false, and one or more tasks were not able to be assigned to workers as a result of optimization, no workers will be assigned tasks and server will response with status code 412."
    },
    {
        name: "worker_backlog_lock",
        required: false,
        type: "boolean",
        default: "false",
        description: "If true tasks that are currently assigned to workers will remain with those workers."
    },
    {
        name: "worker_backlog_order_lock",
        required: false,
        type: "boolean",
        default: "false",
        description: "If true tasks currently assigned to workers will both remain with those workers and maintain their order."
    },
    {
        name: "start_at",
        required: false,
        type: "string",
        default: `"worker_locations" (can be one of "worker_locations", "team_hub", "worker_addresses")`,
        description: "Defines where workers will start their routes."
    },
    {
        name: "end_at",
        required: false,
        type: "string",
        default: `"worker_locations" (can be one of "worker_locations", "team_hub", "worker_addresses")`,
        description: "Defines where workers will end their routes."
    },
    {
        name: "timezone",
        required: false,
        type: "string",
        default: "organization timezone",
        description: "Timezone to be used for timezone dependent data in optimization (such as worker schedules)"
    },
    {
        name: "route_max_tasks",
        required: false,
        type: "integer",
        default: "Maximum number of tasks per route set on account",
        description: "Maximum number of tasks that can be assigned to any one driver’s route"
    },
    {
        name: "task_service_time",
        required: false,
        type: "integer",
        default: "Service time per task (in minutes) set on account",
        description: "Duration of time after worker arrives at a destination but before worker leaves a destination. Applied as a default for tasks that do not have service time set / service time is zero"
    },
    {
        name: "worker_capacity",
        required: false,
        type: "integer",
        default: "Default driver vehicle capacity set on account",
        description: "Max capacity for workers. Applied as default for all workers that do not have a capacity set / capacity is zero"
    },
    {
        name: "worker_schedule",
        required: false,
        type: "[  ]integer",
        default: "Default driver schedule set on account",
        description: "Driver schedule for workers as array of length 2 with shift start as first element and shift end as second element (both in represented as unix timestamp in  milliseconds). Applied as default for all workers that do not have a schedule set for the date being optimization for (aka for_date). If 'worker_schedule' is specified any value specified for 'timezone' becomes irrelevant."
    },
    {
        name: "solver_duration",
        required: false,
        type: "integer",
        default: "5 seconds",
        description: "Maximum amount of time, in seconds, the routing engine can spend optimizing. Increasing the solver duration may or may not improve results."
    }
    /* { */
    /*     name: "offline_workers_enabled" */
    /* }, */
    /* { */
    /*     name: "start_at" */
    /* } */
]
