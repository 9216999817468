import { useState } from "react"
import { adRun } from "calls/ad"
import { auth } from "fb"

// db
import { dbOrgAutoDispatchRunParamsLatestUpdate } from "calls/db"

// stores
import { useOptStore } from "stores/opt"
import { useUserStore } from "stores/global"

// auth
import { authUpdateUserTimezone } from "calls/auth"

// constants
import {
    ErrorMessageGlobalFallback,
    timezones
} from "utils/constants"

// types
import type { Message } from "types"
import type {
    OnfAllTeamsResponseData
} from "../../../../../types/services/onfleet"
import type {
    DbOrg,
    DbAdRunParamsLatest,
    DbStartEndOption
} from "../../../../../types/lib/db"

type RunAutoDispatchProps = {
    teams: OnfAllTeamsResponseData,
    org: DbOrg
}

export default function RunAutoDispatch({
    teams,
    org
}: RunAutoDispatchProps) {
    const adState = useOptStore(state => state.ad.state)
    const adActions = useOptStore(state => state.ad.actions)
    const runParamsLatest = adState.run_params_latest
    const optActions = useOptStore(state => state.actions)
    const user = useUserStore(state => state.user)
    const setUserTimezone = useUserStore(state => state.setUserTimezone)
    const timezoneSelection = adState.timezone

    const [message, setMessage] = useState<Message>({ type: "info", body: "" })
    const [loading, setLoading] = useState(false)
    const [teamId, setTeamId] = useState("")

    const handleTeamChange = (e: any) => {
        const { value } = e.target
        setTeamId(value)
    }

    const submitSaveRunParamsLatest = async (orgId: string, runParamsLatest: DbAdRunParamsLatest) => {
        try {
            await dbOrgAutoDispatchRunParamsLatestUpdate(orgId, runParamsLatest)
        } catch (err) {
            console.error(err)
        }
    }

    const submitRunAutoDispatch = async () => {
        if (teamId === "") {
            setMessage({ type: "error", body: "must select a team" })
            return
        }
        setMessage({ type: "info", body: "" })
        setLoading(true)
        try {
            const orgId = org.id
            submitSaveRunParamsLatest(orgId, runParamsLatest)
            const currentUser = auth.currentUser
            if (!currentUser) {
                throw new Error(`user isn't authenticated`)
            }
            const idToken = await currentUser.getIdToken(true)

            if (user && timezoneSelection && user.timezone !== timezoneSelection) {
                const updateUserTimezone = async function() {
                    await authUpdateUserTimezone(idToken, orgId, timezoneSelection)
                    setUserTimezone(timezoneSelection)
                }
                updateUserTimezone()
            }

            const dispatchId = await adRun(idToken, orgId, {
                team_id: teamId,
                for_date: adState.for_date,
                timezone: timezoneSelection,
                ...runParamsLatest,
            })

            setMessage({ type: "info", body: `dispatch successfully initiated: dispatch id ${dispatchId}` })
        } catch (err: any) {
            setMessage({ type: "error", body: err.message ?? ErrorMessageGlobalFallback })
        }
        setLoading(false)
    }

    if (!user || !org) {
        return null
    }

    if (teams.length === 0) {
        return (
            <div className="w-11/12 mt-5 flex flex-col items-center text-lg font-bold">
                No Onfleet teams found
            </div>
        )
    }

    return (
        <div className="w-11/12 mt-5 flex flex-col items-center">
            <div className="w-full max-h-96 overflow-auto">
                {
                    user?.timezone &&
                    (
                        <div className="w-full mb-4 flex sm:flex-row flex-col items-start sm:items-center">
                            <input
                                type="date"
                                name="for_date"
                                value={adState.for_date}
                                className="text-gray-900 bg-gray-300 rounded outline-none h-8 p-0.5 w-30"
                                onChange={(e) => {
                                    optActions.updateForDate("ad", e.target.value)
                                }}
                            />
                            <select
                                className="w-64 text-sm rounded bg-darker outline-none appearance-none sm:ml-4 mt-2 sm:mt-0 h-8 py-1 px-2"
                                value={timezoneSelection}
                                onChange={(e) => {
                                    optActions.updateTimezone("ad", e.target.value)
                                }}
                            >
                                {timezones.map(tz => {
                                    return (
                                        <option value={tz} key={tz}>
                                            {tz}
                                        </option>
                                    )
                                })}
                            </select>
                        </div>
                    )
                }
                <div className="mb-2">Select team</div>
                <div className="flex flex-col">
                    {teams.map(t => {
                        return (
                            <button
                                className={`text-center mb-2 p-2 border border-dark hover:border-gray-500 rounded cursor-pointer ${teamId === t.id ? "bg-dark" : ""}`}
                                key={t.id}
                                name="team_id"
                                value={t.id}
                                onClick={handleTeamChange}
                            >
                                {t.name}
                            </button>
                        )
                    })}
                </div>
            </div>
            <div className="w-full border-b-2 border-dark my-4" />
            <div className="mb-3 w-full">Select options</div>
            <div className="w-full flex">
                <div className="w-1/2">
                    <div>
                        <div className="flex items-center mb-1">
                            <input
                                type="checkbox"
                                name="omit_flagged_tasks"
                                checked={runParamsLatest.omit_flagged_tasks}
                                className="w-4 h-4 rounded accent-purple"
                                onChange={(e) => {
                                    adActions.updateRunParamsLatestBool("omit_flagged_tasks", e.target.checked)
                                }}
                            />
                            <label className="ml-2 text-sm font-medium">
                                Omit flagged tasks
                            </label>
                        </div>
                        <div className="text-xs sm:text-sm text-gray-500">* tasks with metadata field 'omit_auto_dispatch' will be ignored</div>
                    </div>
                    {/* <div className="mt-4"> */}
                    {/*     <div className="flex items-center mb-1"> */}
                    {/*         <input */}
                    {/*             type="checkbox" */}
                    {/*             name="offline_workers_enabled" */}
                    {/*             checked={runParams.offline_workers_enabled} */}
                    {/*             className="w-4 h-4 rounded accent-purple" */}
                    {/*             onChange={handleRunParamsChange} */}
                    {/*         /> */}
                    {/*         <label className="ml-2 text-sm font-medium"> */}
                    {/*             Dispatch across both online and offline workers */}
                    {/*         </label> */}
                    {/*     </div> */}
                    {/* </div> */}
                    <div className="mt-4">
                        <div className="flex items-center mb-1">
                            <input
                                type="checkbox"
                                name="global_unassigned_enabled"
                                checked={runParamsLatest.global_unassigned_enabled}
                                className="w-4 h-4 rounded accent-purple"
                                onChange={(e) => {
                                    adActions.updateRunParamsLatestBool("global_unassigned_enabled", e.target.checked)
                                }}
                            />
                            <label className="ml-2 text-sm font-medium">
                                Include global unassigned tasks
                            </label>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="flex items-center mb-1">
                            <input
                                type="checkbox"
                                name="allow_unassigned"
                                checked={runParamsLatest.allow_unassigned}
                                className="w-4 h-4 rounded accent-purple"
                                onChange={(e) => {
                                    adActions.updateRunParamsLatestBool("allow_unassigned", e.target.checked)
                                }}
                            />
                            <label className="ml-2 text-sm font-medium">
                                Allow unassigned tasks
                            </label>
                        </div>
                        <div className="text-xs sm:text-sm text-gray-500">* if unchecked, and any tasks remain unassigned after optimization, task assignment will be halted</div>
                    </div>
                </div>
                <div className="w-1/2 ml-5">
                    <div>
                        <div className="flex items-center mb-1">
                            <input
                                type="checkbox"
                                name="worker_backlog_lock"
                                checked={runParamsLatest.worker_backlog_lock}
                                className="w-4 h-4 rounded accent-purple"
                                onChange={(e) => {
                                    adActions.updateRunParamsLatestBool("worker_backlog_lock", e.target.checked)
                                }}
                            />
                            <label className="ml-2 text-sm font-medium">
                                Keep current worker tasks with worker
                            </label>
                        </div>
                        <div className="text-xs sm:text-sm text-gray-500">* if unchecked workers may have tasks reassigned to other workers during the optimization process</div>
                    </div>
                    {
                        runParamsLatest.worker_backlog_lock
                        && (
                            <div className="mt-4">
                                <div className="flex items-center mb-1">
                                    <input
                                        type="checkbox"
                                        name="worker_backlog_order_lock"
                                        checked={runParamsLatest.worker_backlog_order_lock}
                                        className="w-4 h-4 rounded accent-purple"
                                        onChange={(e) => {
                                            adActions.updateRunParamsLatestBool("worker_backlog_order_lock", e.target.checked)
                                        }}
                                    />
                                    <label className="ml-2 text-sm font-medium">
                                        Maintain the order of current worker tasks
                                    </label>
                                </div>
                                <div className="text-xs sm:text-sm text-gray-500">* if checked any new tasks assigned to workers during optimization will be inserted after the last backlogged task</div>
                            </div>

                        )
                    }
                </div>
            </div>
            <div className="w-full border-b-2 border-dark my-4" />
            <div className="w-full">
                {/* <div className="flex items-center"> */}
                {/*     <label className="w-32 mr-4">Start At</label> */}
                {/*     <select */}
                {/*         className="w-40 text-sm rounded p-1 bg-dark outline-none appearance-none" */}
                {/*         name="start_at" */}
                {/*         value={runParams.start_at} */}
                {/*         onChange={handleRunParamsChange} */}
                {/*         disabled */}
                {/*     > */}
                {/*         <option value="worker_locations">Worker Locations</option> */}
                {/*         <option value="team_hub">Team's Hub</option> */}
                {/*         <option value="worker_addresses">Worker Addresses</option> */}
                {/*     </select> */}
                {/* </div> */}
                <div className="flex items-center">
                    <label className="w-32 mr-4">End At</label>
                    <select
                        className="w-40 text-sm rounded p-1 bg-dark outline-none appearance-none"
                        name="end_at"
                        value={runParamsLatest.end_at}
                        onChange={(e) => {
                            adActions.updateRunParamsStartEndAt("end_at", e.target.value as DbStartEndOption)
                        }}
                    >
                        <option value="worker_locations">Worker Locations</option>
                        <option value="team_hub">Team's Hub</option>
                        <option value="worker_addresses">Worker Addresses</option>
                    </select>
                </div>
            </div>
            <div className="w-full border-b-2 border-dark mt-4 mb-8" />
            <div className="w-1/2">
                <div
                    className="h-10 bg-purple p-2 flex items-center justify-center rounded text-whitish hover:bg-purple-light cursor-pointer"
                    onClick={submitRunAutoDispatch}
                >
                    {
                        loading
                            ? <span className="loading-spinner" />
                            : <span>Run Auto Dispatch</span>
                    }
                </div>
            </div>
            <div className={`h-3 mt-4 ${message.type === "error" ? "text-red-error" : ""}`}>{message.body}</div>
        </div>
    )
}
