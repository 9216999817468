import { DateTime } from "luxon"

// stores
import { useUserStore } from "stores/global"

// types
import type {
    Message
} from "types"
import type {
    RoRunResultResponseData
} from "@/../../../../../types/services/ro"
import type {
    OnfAllWorkersResponseData,
} from "@/../../../../../types/services/onfleet"

type RunResultProps = {
    optimizationResult: RoRunResultResponseData,
    handleCloseResultsPanel: () => void,
    processingMessage: Message,
    workers: OnfAllWorkersResponseData,
    workerTaskAssignmentStatus: "none" | "failed" | "succeeded",
    processingWorkerTaskUpdates: boolean,
    handleOptimizationResultsAccept: (optResult: RoRunResultResponseData) => Promise<void>,
    handleReturnToConfigurationSettings: () => void,
    erroredWorkers: string[],
}

export default function RunResult({
    optimizationResult,
    handleCloseResultsPanel,
    processingMessage,
    workers,
    workerTaskAssignmentStatus,
    handleReturnToConfigurationSettings,
    processingWorkerTaskUpdates,
    handleOptimizationResultsAccept,
    erroredWorkers,
}: RunResultProps) {
    const timezone = useUserStore(state => state.user?.timezone)
    const workerBacklogTaskIdsAll: string[] = []
    for (const worker of Object.values(workers)) {
        workerBacklogTaskIdsAll.push(...worker.tasks)
    }
    const workerRoutes = optimizationResult.worker_routes
    const routeCounts = Object.values(workerRoutes)
        .reduce((acc, route) => {
            const updatedCounts = { ...acc }
            let routeHasNewTask = false
            let routeHasBacklogTask = false
            for (const t of route) {
                if (routeHasNewTask && !workerBacklogTaskIdsAll.includes(t)) {
                    continue
                }
                if (routeHasBacklogTask && workerBacklogTaskIdsAll.includes(t)) {
                    continue
                }
                if (routeHasNewTask && routeHasBacklogTask) {
                    break
                }
                if (workerBacklogTaskIdsAll.includes(t)) {
                    updatedCounts.routes_with_backlog_tasks++
                    routeHasBacklogTask = true
                    continue
                }
                if (!workerBacklogTaskIdsAll.includes(t)) {
                    updatedCounts.routes_with_new_tasks++
                    routeHasNewTask = true
                    continue
                }
            }
            return updatedCounts
        }, { routes_with_backlog_tasks: 0, routes_with_new_tasks: 0 })

    const assignedTasks = Object.values(optimizationResult.worker_routes).reduce((acc, workerTasks) => {
        return [...acc, ...workerTasks]
    }, [])

    const backlogAssignedTasks = assignedTasks.filter(t => workerBacklogTaskIdsAll.includes(t))
    const newlyAssignedTasks = assignedTasks.filter(t => !workerBacklogTaskIdsAll.includes(t))

    const vehicleSummaries = optimizationResult.vehicle_summaries
    const unassignedTasks = optimizationResult.unassigned_tasks
    const warnings = optimizationResult.warnings

    const backlogUnassignedTasks = unassignedTasks.filter(t => workerBacklogTaskIdsAll.includes(t))
    const selectedUnassignedTasks = unassignedTasks.filter(t => !workerBacklogTaskIdsAll.includes(t))


    return (
        <div className="flex justify-center w-2/3 text-sm sm:text-base border-l-2 border-dark ml-8">
            <div className="h-full flex flex-col items-center w-full">
                <div className="w-full flex items-center p-2">
                    <span
                        className="material-symbols-outlined hover:cursor-pointer text-3xl"
                        onClick={handleCloseResultsPanel}
                    >
                        arrow_back
                    </span>
                </div>
                <div className="flex flex-col items-center mt-2 p-4 rounded w-3/4 overflow-y-auto">
                    <div className="my-2 text-3xl flex">
                        <span className="material-symbols-outlined text-7xl text-green">
                            task_alt
                        </span>
                    </div>
                    <div className={"mt-2 mb-5 text-base sm:text-lg text-purple text-center"}>
                        {processingMessage.body}
                        {
                            !!optimizationResult &&
                            <div className="text-gray-500 text-sm mt-2"><span className="text-gray-400 mr-2">dispatch id: </span>{optimizationResult.dispatch_id}</div>
                        }
                    </div>
                    <div className="flex flex-col border border-darker p-4 w-full mb-4 rounded-md">
                        {
                            newlyAssignedTasks.length > 0 &&
                            (
                                <div className="mb-1 text-sm">
                                    {`${newlyAssignedTasks.length} tasks optimized across ${routeCounts.routes_with_new_tasks} routes`}
                                </div>
                            )
                        }
                        {
                            backlogAssignedTasks.length > 0 &&
                            (
                                <div className="mb-1 text-sm text-gray-500">
                                    {`${backlogAssignedTasks.length} backlogged tasks optimized across ${routeCounts.routes_with_backlog_tasks} route`}
                                </div>
                            )
                        }
                        {
                            unassignedTasks.length > 0 &&
                            (
                                <div className="mb-1 text-sm text-red-error opacity-80">
                                    {
                                        selectedUnassignedTasks.length > 0 &&
                                        <div className="mb-1">{`${selectedUnassignedTasks.length} tasks unable to be assigned`}</div>
                                    }
                                    {
                                        backlogUnassignedTasks.length > 0 &&
                                        <div className="text-gray-500">{`${backlogUnassignedTasks.length} backlogged tasks excluded from optimization`}</div>
                                    }
                                </div>
                            )
                        }
                        {
                            warnings.length > 0 &&
                            (
                                <div className="mb-1 text-sm text-yellow">
                                    {
                                        warnings.map((warningStr, index) => {
                                            return <p key={index}>{warningStr}</p>
                                        })
                                    }
                                </div>
                            )
                        }
                    </div>
                    <div className="mt-2 w-full">
                        <div className="overflow-x-auto relative">
                            <table className="w-full text-sm text-left">
                                <thead className="text-xs uppercase bg-dark bg-opacity-50">
                                    <tr>
                                        <th scope="col" className="py-3 px-6">
                                            Route Name
                                        </th>
                                        <th scope="col" className="py-3 px-6">
                                            Tasks
                                        </th>
                                        <th scope="col" className="py-3 px-6">
                                            Distance
                                        </th>
                                        <th scope="col" className="py-3 px-6">
                                            Time on Duty
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        vehicleSummaries
                                            ?.map(el => {
                                                const matchedWorkerRoute = workerRoutes[el.id]
                                                if (!matchedWorkerRoute) {
                                                    return null
                                                }
                                                const vehicleRouteSummary = el.route
                                                if (!Array.isArray(vehicleRouteSummary)) {
                                                    return null
                                                }
                                                if (vehicleRouteSummary.length === 0) {
                                                    return null
                                                }

                                                const firstStop = vehicleRouteSummary[0]
                                                const lastStop = vehicleRouteSummary[vehicleRouteSummary.length - 1]

                                                const startDate = timezone
                                                    ? DateTime.fromISO(firstStop.ets, { zone: timezone })
                                                    : DateTime.fromISO(firstStop.ets)

                                                const endDate = timezone
                                                    ? DateTime.fromISO(lastStop.etd, { zone: timezone })
                                                    : DateTime.fromISO(lastStop.etd)

                                                const duration = endDate.diff(startDate, ["hours", "minutes"])
                                                const hours = duration.hours
                                                const minutes = Math.round(duration.minutes)

                                                let prettyTimeOnDuty = ""
                                                if (hours > 0) {
                                                    prettyTimeOnDuty += `${hours} ${hours === 1 ? "hour" : "hours"}`
                                                }
                                                if (minutes > 0) {
                                                    if (hours > 0) {
                                                        prettyTimeOnDuty += " and "
                                                    }
                                                    prettyTimeOnDuty += `${minutes} ${minutes === 1 ? "minute" : "minutes"}`
                                                }
                                                if (hours === 0 && minutes === 0) {
                                                    prettyTimeOnDuty = "now"
                                                } else if (hours === 0) {
                                                    prettyTimeOnDuty = `${minutes} ${minutes === 1 ? "minute" : "minutes"}`
                                                } else if (minutes === 0) {
                                                    prettyTimeOnDuty = `${hours} ${hours === 1 ? "hour" : "hour"}`
                                                }

                                                function metersToMiles(meters: number) {
                                                    return Math.round(meters * 0.000621371192).toString()
                                                }

                                                return (
                                                    <tr key={el.id}>
                                                        <th className="py-4 px-6">{workers[el.id]?.name}</th>
                                                        <td className="py-4 px-6">{matchedWorkerRoute.length}</td>
                                                        <td className="py-4 px-6">
                                                            {
                                                                typeof el.travel_distance === "number"
                                                                    ? `${metersToMiles(el?.travel_distance)} miles`
                                                                    : "unprocessable"
                                                            }
                                                        </td>
                                                        <td className="py-4 px-6">{prettyTimeOnDuty}</td>
                                                    </tr>
                                                )
                                            })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        (workerTaskAssignmentStatus !== "none" || (optimizationResult?.worker_routes && Object.keys(optimizationResult.worker_routes).length === 0))
                            ? (
                                <div
                                    className="border border-gray-500 h-9 flex items-center justify-center bg-black bg-opacity-60 text-gray-300 hover:bg-gray-900 p-2 rounded w-full cursor-pointer mt-10 mb-2"
                                    onClick={handleReturnToConfigurationSettings}
                                >
                                    Return to configuration settings
                                </div>
                            )
                            : (workerTaskAssignmentStatus === "none")
                                ? (
                                    <div
                                        className="h-9 flex items-center justify-center bg-purple text-whitish hover:bg-purple-light p-2 rounded w-full cursor-pointer mt-10 mb-2"
                                    >
                                        {
                                            processingWorkerTaskUpdates
                                                ? <span className="loading-spinner" />
                                                : <span
                                                    className="w-full flex justify-center items-center"
                                                    onClick={() => handleOptimizationResultsAccept(optimizationResult)}>
                                                    Accept
                                                </span>
                                        }

                                    </div>
                                )
                                : null
                    }
                    <div className="mt-5">
                        {
                            (workerTaskAssignmentStatus === "succeeded" && erroredWorkers.length > 0)
                                ? (
                                    <div className="flex flex-col justify-center items-center">
                                        <div className="text-red-error">Errors occurred updating tasks for the following workers</div>
                                        <div className="mt-2">{erroredWorkers.join(", ")}</div>
                                    </div>
                                )
                                : (workerTaskAssignmentStatus === "succeeded" && erroredWorkers.length === 0)
                                    ? (
                                        <div className="flex flex-col justify-center items-center">
                                            <div className="text-green">Successfully updated worker tasks</div>
                                        </div>
                                    )
                                    : (workerTaskAssignmentStatus === "failed")
                                        ? (
                                            <div className="flex flex-col justify-center items-center">
                                                <div className="text-red-error">An unexpected error occurred. Please contact support.</div>
                                            </div>

                                        )
                                        : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
