import type {
    Message
} from "types"

type ProcessingProgressProps = {
    handleCloseProcessingPanel: () => void,
    processingMessage: Message,
}

export default function ProcessingProgress({
    handleCloseProcessingPanel,
    processingMessage,
}: ProcessingProgressProps) {
    return (
        <div className="flex justify-center w-2/3 text-sm sm:text-base border-l-2 border-dark ml-8">
            <div className="h-full flex flex-col items-center w-full">
                <div className="w-full flex items-center p-2">
                    <span
                        className="material-symbols-outlined hover:cursor-pointer text-3xl"
                        onClick={handleCloseProcessingPanel}
                    >
                        arrow_back
                    </span>
                </div>
                <div className="flex flex-col items-center mt-2 p-4 rounded w-3/4 overflow-y-auto">
                    <div className={`mb-2 text-base sm:text-lg ${processingMessage.type === "error" ? "text-red-error" : ""}`}>
                        {processingMessage.body}
                    </div>
                    {
                        processingMessage.type === "error"
                            ? (
                                <span className="material-symbols-outlined mt-5 text-5xl">
                                    sentiment_dissatisfied
                                </span>
                            )
                            : (

                                <div className="optimization-loader mt-5" />
                            )
                    }
                </div>
            </div>
        </div>
    )
}
