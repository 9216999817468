import config from "config"

// constants
import { ErrorMessageGlobalFallback } from "utils/constants"

// types
import type { ServerResponse } from "../../../types/server"
import type {
    AdRequestParams,
    AdRunResultResponseData
} from "../../../types/services/ad"


export async function adRun(idToken: string, orgId: string, body: AdRequestParams) {
    const headers = {
        "id-token": idToken,
        "org-id": orgId,
        "Content-Type": "application/json"
    }
    const res = await fetch(config.serverURL + "/ad/run", {
        method: "POST",
        headers: headers,
        body: JSON.stringify(body)
    })

    const resObj = await res.json() as ServerResponse
    if (res.status !== 200) {
        const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
        throw new Error(errMessage)
    }
    const adRunResultData = resObj.data as AdRunResultResponseData
    return adRunResultData.dispatch_id
}

/* export async function adGetRunStatus(idToken: string, orgId: string, runId: string) { */
/*     const headers = { */
/*         "id-token": idToken, */
/*         "org-id": orgId, */
/*         "Accept": "application/json" */
/*     } */
/*     const res = await fetch(config.serverURL + `/ad/run/status?run_id=${runId}`, { headers }) */
/*     const data = await res.json() */
/*     if (res.status !== 200) { */
/*         if (data?.error?.type === "actionable_error" && typeof data?.error?.message === "string") { */
/*             throw new Error(data.error.message) */
/*         } */
/*         throw new Error("an unexpected error occurred") */
/*     } */
/*     return data.data.runStatus */
/* } */
