import {
    onIdTokenChanged,
    getIdTokenResult,
    signOut,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
} from "firebase/auth"

import config from "config"
import { auth } from "fb"

// constants
import { ErrorMessageGlobalFallback } from "utils/constants"

// types
import type { SetOrg, SetUser } from "stores/global"
import type { SyntheticEvent } from "react"
import type { Message } from "types"
import type { UserCustomClaims } from "../../../types/user"
import type { ServerResponse } from "../../../types/server"

export async function authUpdateUserTimezone(idToken: string, orgId: string, timezone: string) {
    try {
        const headers = {
            "id-token": idToken,
            "org-id": orgId,
            "Content-Type": "application/json"
        }
        const body = { timezone: timezone }
        const res = await fetch(config.serverURL + "/accounts/user/update/timezone", {
            method: "POST",
            body: JSON.stringify(body),
            headers: headers
        })
        if (res.status !== 200) {
            const resObj = await res.json() as ServerResponse
            const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
            throw new Error(errMessage)
        }
    } catch (err: any) {
        console.error(err)
    }
}

export async function authAdminUserCreate(email: string, idToken: string, orgId: string) {
    const headers = {
        "id-token": idToken,
        "org-id": orgId,
        "Content-Type": "application/json"
    }
    const body = { email: email }
    const res = await fetch(config.serverURL + "/accounts/admin/add", {
        method: "POST",
        body: JSON.stringify(body),
        headers: headers
    })
    if (res.status !== 200) {
        const resObj = await res.json() as ServerResponse
        const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
        throw new Error(errMessage)
    }
}

export async function authListAdmins(idToken: string, orgId: string, setter: (users: any) => void) {
    const headers = {
        "id-token": idToken,
        "org-id": orgId,
        "Content-Type": "application/json"
    }
    const res = await fetch(config.serverURL + "/accounts/admin/list", {
        method: "GET",
        headers: headers
    })
    const resObj = await res.json() as ServerResponse
    if (res.status !== 200) {
        const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
        throw new Error(errMessage)
    }
    setter(resObj.data)
}

export async function authDeleteAdmin(idToken: string, orgId: string, uid: string) {
    const headers = {
        "id-token": idToken,
        "org-id": orgId,
        "Content-Type": "application/json"
    }
    const res = await fetch(config.serverURL + `/accounts/admin/delete/${uid}`, {
        method: "DELETE",
        headers: headers
    })
    if (res.status !== 200) {
        const resObj = await res.json() as ServerResponse
        const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
        throw new Error(errMessage)

    }
}


export function authStateSubscribe(setUser: SetUser, setOrg: SetOrg) {
    const unsub = onIdTokenChanged(auth, async (user) => {
        try {
            if (!user) {
                setUser(null)
                setOrg(null)
                return
            }
            const token = await getIdTokenResult(user)
            const claims = token.claims as UserCustomClaims
            setUser({
                uid: user.uid,
                email: user.email,
                orgId: claims.org,
                role: claims.role,
                timezone: claims.timezone
            })
        } catch (err) {
            console.error(err)
            setUser(null)
        }
    })
    return unsub
}

export async function signoutUser() {
    try {
        await signOut(auth)
    } catch (err: any) {
        console.error(err.message)
    }
}

export async function signinUser(
    e: SyntheticEvent,
    email: string,
    password: string,
    setLoading: (el: boolean) => void,
    setErrMessage: (el: string) => void
) {
    e.preventDefault()
    setLoading(true)
    try {
        if (!email) {
            throw new Error("invalid email")
        }
        if (!password) {
            throw new Error("invalid password")
        }
        await signInWithEmailAndPassword(auth, email, password)
    } catch (err: any) {
        if (err.code) {
            setErrMessage(err.code)
        } else {
            setErrMessage(err.message)
        }
    }
    setLoading(false)
}

export async function passwordResetSend(
    email: string,
    setProcessingPasswordReset: (el: boolean) => void,
    setResPasswordReset: (el: Message) => void
) {
    setProcessingPasswordReset(true)
    try {
        await sendPasswordResetEmail(auth, email)
        setResPasswordReset({ type: "info", body: "password reset email sent" })
    } catch (err: any) {
        console.error(err.message)
        setResPasswordReset({ type: "error", body: err?.code ? err.code : "oops. something went wrong" })
    }
    setProcessingPasswordReset(false)
}

