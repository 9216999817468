import { create } from "zustand"

import type { DbOrg } from "../../../types/lib/db"
import type { UserRole } from "../../../types/user"

export type User = {
    uid: string,
    email: string | null,
    orgId: string,
    role: UserRole,
    timezone: string
} | null

export type SetUser = (user: User) => void
export type SetUserTimezone = (timezone: string) => void

type UserState = {
    user: User,
    setUser: SetUser,
    setUserTimezone: (timezone: string) => void
}
export const useUserStore = create<UserState>((set) => ({
    user: null,
    setUser: (user) => set(() => ({ user: user })),
    setUserTimezone: (timezone) => set((state) => {
        if (!state.user) {
            return { user: null }
        }
        return {
            user: {
                ...state.user,
                timezone: timezone
            }
        }
    })
}))

type ShadowOrgState = {
    shadowOrg: DbOrg | null,
    setShadowOrg: (org: DbOrg | null) => void
}
export const useShadowOrgStore = create<ShadowOrgState>((set) => ({
    shadowOrg: null,
    setShadowOrg: (shadowOrg) => set(() => ({ shadowOrg: shadowOrg }))
}))

export type SetOrg = (org: DbOrg | null) => void

type OrgState = {
    org: DbOrg | null,
    setOrg: SetOrg
}

export const useOrgStore = create<OrgState>((set) => ({
    org: null,
    setOrg: (org) => set(() => ({ org: org }))
}))
