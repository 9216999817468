import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

/* import { getAnalytics } from "firebase/analytics"; */

const firebaseConfig = {
    apiKey: "AIzaSyA8oFERsaLDACbw2KOt4dU4EN7zTs3F2Dw",
    authDomain: "routeopt-5b1ea.firebaseapp.com",
    projectId: "routeopt-5b1ea",
    storageBucket: "routeopt-5b1ea.appspot.com",
    messagingSenderId: "514567042097",
    appId: "1:514567042097:web:72dcfe1235256db6dc1110",
    measurementId: "G-8PDZKNGPXF"
}

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const db = getFirestore(app)

/* const analytics = getAnalytics(app); */

export {
    auth,
    db
}
