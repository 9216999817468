import config from "config"

// constants
import { ErrorMessageGlobalFallback } from "utils/constants"

// types
import type { ServerResponse } from "../../../types/server"

export async function tokenFetch(idToken: string, orgId: string, setter: (el: any) => void) {
    try {
        const headers = {
            "id-token": idToken,
            "org-id": orgId,
            "Content-Type": "application/json"
        }
        const res = await fetch(config.serverURL + "/token/get", {
            method: "GET",
            headers
        })

        const resObj = await res.json() as ServerResponse
        if (res.status !== 200) {
            const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
            throw new Error(errMessage)
        }

        setter(resObj?.data?.token ?? "")
    } catch (err: any) {
        console.error(err)
    }
}
