import config from "config"

// constants
import { ErrorMessageGlobalFallback } from "utils/constants"

// types
import type {
    OnfAllTeamsResponseData,
    OnfAllWorkersResponseData,
    OnfHubsResponseData,
    OnfOrganizationResponseData,
    OnfTasksListResponseData
} from "../../../types/services/onfleet"
import type { ServerResponse } from "../../../types/server"

export async function onfleetGetTeams(idToken: string, orgId: string, setter: (el: OnfAllTeamsResponseData) => void) {
    const headers = {
        "id-token": idToken,
        "org-id": orgId
    }
    const res = await fetch(config.serverURL + "/onf/teams_all", {
        method: "GET",
        headers
    })

    const resObj = await res.json() as ServerResponse
    if (res.status !== 200) {
        const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
        throw new Error(errMessage)
    }
    setter(resObj.data as OnfAllTeamsResponseData)
}

export async function onfleetGetWorkers(idToken: string, orgId: string, setter: (el: OnfAllWorkersResponseData) => void) {
    const headers = {
        "id-token": idToken,
        "org-id": orgId
    }
    const res = await fetch(config.serverURL + "/onf/workers", {
        method: "GET",
        headers
    })
    const resObj = await res.json() as ServerResponse
    if (res.status !== 200) {
        const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
        throw new Error(errMessage)
    }
    setter(resObj.data as OnfAllWorkersResponseData)
}

export async function onfleetGetTasks(idToken: string, orgId: string, date: string, setter: (el: OnfTasksListResponseData) => void) {
    const headers = {
        "id-token": idToken,
        "org-id": orgId
    }

    const url = config.serverURL + `/onf/tasks?date=${date}`

    const res = await fetch(url, { headers })

    const resObj = await res.json() as ServerResponse
    if (res.status !== 200) {
        const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
        throw new Error(errMessage)
    }
    setter(resObj.data as OnfTasksListResponseData)
}



export async function onfleetGetHubs(idToken: string, orgId: string, setter: (el: OnfHubsResponseData) => void) {
    const headers = {
        "id-token": idToken,
        "org-id": orgId
    }
    const res = await fetch(config.serverURL + "/onf/hubs", { headers })
    const resObj = await res.json() as ServerResponse
    if (res.status !== 200) {
        const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
        throw new Error(errMessage)
    }
    setter(resObj.data as OnfHubsResponseData)
}

export async function onfleetGetOrganizationDetails(idToken: string, orgId: string, setter: (el: OnfOrganizationResponseData) => void) {
    const headers = {
        "id-token": idToken,
        "org-id": orgId
    }
    const res = await fetch(config.serverURL + "/onf/organization_details", { headers })
    const resObj = await res.json() as ServerResponse
    if (res.status !== 200) {
        const errMessage = resObj?.error?.message ?? ErrorMessageGlobalFallback
        throw new Error(errMessage)
    }
    setter(resObj.data as OnfOrganizationResponseData)
}
