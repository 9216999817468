import { useState, useEffect } from "react"
import { DateTime } from "luxon"
import { onfleetGetTeams } from "calls/onfleet"
import { auth } from "fb"
import { adEmptyState, useOptStore } from "stores/opt"
import { useUserStore } from "stores/global"

import ApiDoc from "shared/components/ApiDoc"
import RunAutoDispatch from "./components/RunAutoDispatch"
import ConfigurationSettings from "./components/ConfigurationSettings"
import Loading from "pages/Loading"

import {
    apiUrl,
    apiMethod,
    apiDocReqParams,
    apiDocExampleReqBody
} from "./ApiSpec"

// types
import type {
    OnfAllTeamsResponseData
} from "@/../../../types/services/onfleet"
import type { DbOrg } from "@/../../../types/lib/db"
import type { Message } from "types"

type AutoDispatchProps = {
    org: DbOrg,
    token: string,
}

type View = "api" | "run_auto_dispatch" | "configuration_settings"

export default function AutoDispatch({
    org,
    token
}: AutoDispatchProps) {
    const user = useUserStore(state => state.user)
    const adState = useOptStore(state => state.ad.state)
    const adActions = useOptStore(state => state.ad.actions)
    const [teams, setTeams] = useState<OnfAllTeamsResponseData>([])
    const [loading, setLoading] = useState(true)
    const [message, setMessage] = useState<Message>({ type: "info", body: "" })
    const [view, setView] = useState<View>("run_auto_dispatch")

    const adHidden = org.services.auto_dispatch.hidden

    useEffect(() => {
        async function load() {
            try {
                const currentUser = auth.currentUser
                if (!currentUser) {
                    throw new Error(`user isn't authenticated`)
                }
                const idToken = await currentUser.getIdToken(true)
                const orgId = org.id
                await onfleetGetTeams(idToken, orgId, setTeams)
                setLoading(false)
                return
            } catch (err) {
                console.error(err)
            }
            setLoading(false)
        }
        load()
    }, [])

    useEffect(() => {
        if (org && user) {
            const initForDate = user?.timezone
                ? DateTime.now().setZone(user.timezone).toFormat("yyyy-MM-dd")
                : DateTime.now().toFormat("yyyy-MM-dd")


            adActions.initAdState({
                ...adEmptyState,
                for_date: initForDate,
                timezone: user?.timezone ?? "America/Los_Angeles",
                initialize_state: false,
                ...(org && {
                    optimization_settings: org.services.auto_dispatch.optimization_settings,
                    simple_quantity_management: org.services.auto_dispatch.simple_quantity_management,
                    run_params_latest: org.services.auto_dispatch.run_params_latest
                })
            })
        }
    }, [adState.initialize_state])

    const handleSetView = (val: View) => {
        if (!["api", "run_auto_dispatch", "configuration_settings"].includes(val)) {
            return
        }
        setView(val)
    }

    const renderView = (viewSelected: View) => {
        switch (viewSelected) {
            case "api":
                return (
                    <div className="mx-12">
                        <ApiDoc
                            token={token}
                            params={
                                apiDocReqParams.filter(p => {
                                    if (p.name === "teams" && adHidden.includes("teams_param")) {
                                        return false
                                    }
                                    return true
                                })
                            }
                            body={apiDocExampleReqBody}
                            method={apiMethod}
                            url={apiUrl}
                        />
                    </div>
                )
            case "run_auto_dispatch":
                return (
                    <RunAutoDispatch
                        teams={teams}
                        org={org}
                    />
                )
            case "configuration_settings":
                return (
                    <ConfigurationSettings
                        org={org}
                    />
                )
            default:
                return
        }
    }


    if (loading) {
        return (
            <div className="flex flex-col items-center p-5 text-gray-400 h-5/6">
                <Loading />
            </div>
        )
    }

    return (
        <div className="flex flex-col items-center p-5 text-gray-400 overflow-auto h-5/6">
            <div className={`min-h-6 ${message.type === "error" ? "text-red-error" : "text-purple"}`}>
                {message.body}
            </div>
            <div className="w-11/12 flex justify-between items-center text-sm mb-4">
                <div className="flex">
                    <div
                        className={`flex items-center p-2 rounded w-40 mr-2 cursor-pointer ${view === "run_auto_dispatch" ? "border border-gray-500" : "border border-dark"}`}
                        onClick={() => handleSetView("run_auto_dispatch")}
                    >
                        Run Auto Dispatch
                    </div>
                    <div
                        className={`flex items-center p-2 rounded w-40 ml-2 cursor-pointer ${view === "api" ? "border border-gray-500" : "border border-dark"}`}
                        onClick={() => handleSetView("api")}
                    >
                        <span className="material-symbols-outlined mr-2">
                            code
                        </span>
                        API
                    </div>
                </div>
                {
                    (org && !adHidden.includes("optimization_settings")) &&
                    (
                        <div className="flex">
                            <div
                                className={`p-2 w-40 flex items-center justify-center cursor-pointer rounded hover:bg-dark ${view === "configuration_settings" ? "bg-dark" : ""}`}

                                onClick={() => handleSetView("configuration_settings")}
                            >
                                <span className="material-symbols-outlined mr-2">
                                    settings
                                </span>
                                Configuration
                            </div>
                        </div>
                    )
                }
            </div>
            {renderView(view)}
        </div>
    )
}
