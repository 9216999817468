import { DateTime } from "luxon"
import {
    onSnapshot,
    doc,
    runTransaction,
    query,
    collection,
    orderBy,
    getDocs
} from "firebase/firestore"

import { db } from "fb"

// utils
import { vehicleSpeedUnitTypes } from "utils/constants"


import { optMeasures } from "utils/constants"

// types
import {
    DbAdRunParamsLatest,
    DbOptimizationSettings,
    DbOptMeasure,
    DbService
} from "../../../types/lib/db"

export function dbOrgListen(orgId: string, setter: (el: any) => void) {
    const unsub = onSnapshot(doc(db, "orgs", orgId), (snap) => {
        const orgData = snap.data()
        setter(orgData)
    }, (err) => {
        console.error(err)
    })
    return unsub
}

export async function dbGetOrgsAll(setter: (el: any) => void) {
    const q = query(
        collection(db, "orgs"),
        orderBy("name", "asc")
    )
    const orgDocs = await getDocs(q)
    const orgDataArr = orgDocs.docs.map(d => d.data())
    setter(orgDataArr)
}

export async function dbOrgSimpleQuantityManagementSettingUpdate(orgId: string, service: DbService, simpleQuantityManagement: boolean) {
    try {

        await runTransaction(db, async (transaction) => {
            const orgDocRef = doc(db, "orgs", orgId)
            const orgDoc = await transaction.get(orgDocRef)
            if (!orgDoc.exists()) {
                throw new Error(`db document for org with id ${orgId} does not exist`)
            }
            transaction.update(orgDocRef, {
                updated_at: DateTime.now().toMillis(),
                [`services.${service}.simple_quantity_management`]: simpleQuantityManagement
            })
        })
    } catch (err: any) {
        console.error(err)
    }
}

export async function dbOrgMeasureUpdate(orgId: string, service: DbService, measure: DbOptMeasure) {
    try {
        await runTransaction(db, async (transaction) => {
            const orgDocRef = doc(db, "orgs", orgId)
            const orgDoc = await transaction.get(orgDocRef)
            if (!orgDoc.exists()) {
                throw new Error(`db document for org with id ${orgId} does not exist`)
            }
            transaction.update(orgDocRef, {
                updated_at: DateTime.now().toMillis(),
                [`services.${service}.optimization_settings.measure`]: measure
            })
        })
    } catch (err: any) {
        console.error(err)
    }

}

export async function dbOrgRouteOptimizationSettingsUpdate(orgId: string, optimizationSettings: DbOptimizationSettings) {
    try {
        await runTransaction(db, async (transaction) => {
            const orgDocRef = doc(db, "orgs", orgId)
            const orgDoc = await transaction.get(orgDocRef)
            if (!orgDoc.exists()) {
                throw new Error(`db document for org with id ${orgId} does not exist`)
            }
            const {
                measure,
                route_max_tasks,
                task_service_time,
                task_max_delay,
                task_quantity,
                solver_duration,
                worker_capacity,
                worker_schedule,
                worker_vehicle_speed
            } = optimizationSettings

            const updatedOptimizationSettings = {
                measure: optMeasures.includes(measure) ? measure : "default",
                route_max_tasks: typeof route_max_tasks === "number" ? Math.round(Math.abs(route_max_tasks)) : 1,
                task_service_time: typeof task_service_time === "number" ? Math.round(Math.abs(task_service_time)) : 1,
                task_max_delay: typeof task_max_delay === "number" ? Math.round(Math.abs(task_max_delay)) : 1,
                task_quantity: typeof task_quantity === "number" ? Math.round(Math.abs(task_quantity)) : 1,
                solver_duration: typeof solver_duration === "number" ? Math.round(Math.abs(solver_duration)) : 5,
                worker_capacity: typeof worker_capacity === "number" ? Math.round(Math.abs(worker_capacity)) : 1,
                worker_schedule: worker_schedule,
                worker_vehicle_speed: {
                    car: {
                        value: typeof worker_vehicle_speed.car.value === "number" ? worker_vehicle_speed.car.value : 30,
                        unit: vehicleSpeedUnitTypes.includes(worker_vehicle_speed.car.unit) ? worker_vehicle_speed.car.unit : "mph"
                    },
                    truck: {
                        value: typeof worker_vehicle_speed.truck.value === "number" ? worker_vehicle_speed.truck.value : 25,
                        unit: vehicleSpeedUnitTypes.includes(worker_vehicle_speed.truck.unit) ? worker_vehicle_speed.truck.unit : "mph"
                    },
                    motorcycle: {
                        value: typeof worker_vehicle_speed.motorcycle.value === "number" ? worker_vehicle_speed.motorcycle.value : 30,
                        unit: vehicleSpeedUnitTypes.includes(worker_vehicle_speed.motorcycle.unit) ? worker_vehicle_speed.motorcycle.unit : "mph"
                    },
                    bicycle: {
                        value: typeof worker_vehicle_speed.bicycle.value === "number" ? worker_vehicle_speed.bicycle.value : 15,
                        unit: vehicleSpeedUnitTypes.includes(worker_vehicle_speed.bicycle.unit) ? worker_vehicle_speed.bicycle.unit : "mph"
                    },
                    walking: {
                        value: typeof worker_vehicle_speed.walking.value === "number" ? worker_vehicle_speed.walking.value : 5,
                        unit: vehicleSpeedUnitTypes.includes(worker_vehicle_speed.walking.unit) ? worker_vehicle_speed.walking.unit : "mph"
                    }
                }
            }
            transaction.update(orgDocRef, {
                updated_at: DateTime.now().toMillis(),
                'services.route_optimization.optimization_settings': updatedOptimizationSettings
            })
        })
    } catch (err) {
        console.error(err)
    }
}

export async function dbOrgAutoDispatchOptimizationSettingsUpdate(orgId: string, optimizationSettings: DbOptimizationSettings) {
    await runTransaction(db, async (transaction) => {
        const orgDocRef = doc(db, "orgs", orgId)
        const orgDoc = await transaction.get(orgDocRef)
        if (!orgDoc.exists()) {
            throw new Error(`db document for org with id ${orgId} does not exist`)
        }
        const {
            measure,
            route_max_tasks,
            task_service_time,
            task_max_delay,
            task_quantity,
            solver_duration,
            worker_capacity,
            worker_schedule,
            worker_vehicle_speed
        } = optimizationSettings

        const updatedOptimizationSettings = {
            measure: optMeasures.includes(measure) ? measure : "default",
            route_max_tasks: typeof route_max_tasks === "number" ? Math.round(Math.abs(route_max_tasks)) : 1,
            task_service_time: typeof task_service_time === "number" ? Math.round(Math.abs(task_service_time)) : 1,
            task_max_delay: typeof task_max_delay === "number" ? Math.round(Math.abs(task_max_delay)) : 1,
            task_quantity: typeof task_quantity === "number" ? Math.round(Math.abs(task_quantity)) : 1,
            solver_duration: typeof solver_duration === "number" ? Math.round(Math.abs(solver_duration)) : 5,
            worker_capacity: typeof worker_capacity === "number" ? Math.round(Math.abs(worker_capacity)) : 1,
            worker_schedule: worker_schedule,
            worker_vehicle_speed: {
                car: {
                    value: typeof worker_vehicle_speed.car.value === "number" ? worker_vehicle_speed.car.value : 30,
                    unit: vehicleSpeedUnitTypes.includes(worker_vehicle_speed.car.unit) ? worker_vehicle_speed.car.unit : "mph"
                },
                truck: {
                    value: typeof worker_vehicle_speed.truck.value === "number" ? worker_vehicle_speed.truck.value : 25,
                    unit: vehicleSpeedUnitTypes.includes(worker_vehicle_speed.truck.unit) ? worker_vehicle_speed.truck.unit : "mph"
                },
                motorcycle: {
                    value: typeof worker_vehicle_speed.motorcycle.value === "number" ? worker_vehicle_speed.motorcycle.value : 30,
                    unit: vehicleSpeedUnitTypes.includes(worker_vehicle_speed.motorcycle.unit) ? worker_vehicle_speed.motorcycle.unit : "mph"
                },
                bicycle: {
                    value: typeof worker_vehicle_speed.bicycle.value === "number" ? worker_vehicle_speed.bicycle.value : 15,
                    unit: vehicleSpeedUnitTypes.includes(worker_vehicle_speed.bicycle.unit) ? worker_vehicle_speed.bicycle.unit : "mph"
                },
                walking: {
                    value: typeof worker_vehicle_speed.walking.value === "number" ? worker_vehicle_speed.walking.value : 5,
                    unit: vehicleSpeedUnitTypes.includes(worker_vehicle_speed.walking.unit) ? worker_vehicle_speed.walking.unit : "mph"
                }
            }
        }

        transaction.update(orgDocRef, {
            updated_at: DateTime.now().toMillis(),
            'services.auto_dispatch.optimization_settings': updatedOptimizationSettings
        })
    })
}

export async function dbOrgAutoDispatchRunParamsLatestUpdate(orgId: string, runParamsLatest: DbAdRunParamsLatest) {
    await runTransaction(db, async (transaction) => {
        const orgDocRef = doc(db, "orgs", orgId)
        const orgDoc = await transaction.get(orgDocRef)
        if (!orgDoc.exists()) {
            throw new Error(`db document for org with id ${orgId} does not exist`)
        }
        transaction.update(orgDocRef, {
            updated_at: DateTime.now().toMillis(),
            'services.auto_dispatch.run_params_latest': runParamsLatest
        })
    })
}
