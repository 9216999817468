import { useState } from "react"
import Markdown from "react-markdown"
import SyntaxHighlighter from "react-syntax-highlighter"
import { nord } from "react-syntax-highlighter/dist/esm/styles/hljs"

export type ParamDetails = {
    name: string,
    required: boolean,
    type: string,
    default: string | null,
    description: string
}

type ApiDocProps = {
    token: string,
    params: ParamDetails[],
    body: any,
    method: string,
    url: string
}

const payload200Markdown = `
~~~typescript
{
    "status": "success",
    "data": {
        "dispatch_id": string,
        "unassigned_tasks": []string,
        "warnings": []string,
        "worker_routes": {
            [workerId: string]: []string
        }
    }
}
~~~
`

const payloadErrorMarkdown = `
~~~typescript
{
    "status": "error",
    "error": {
        "type": string,
        "message": string,
        // payload is included only when applicable / contains data to relay
        "payload": {
            "over_assigned_workers": []string
        }
    }
}
~~~
`

export default function ApiDoc({
    token,
    params,
    body,
    method,
    url
}: ApiDocProps) {
    const [tokenVisible, setTokenVisible] = useState(false)

    const codeToString = (codeString: any) => {
        return JSON.stringify(codeString, null, 4)
    }

    const copyToClipboard = (val: string) => {
        navigator.clipboard.writeText(val)
    }

    const toggleTokenVisibility = () => {
        setTokenVisible(state => !state)
    }


    return (
        <div className="w-full mt-5">
            <div>
                <div className="text-xs sm:text-sm mb-2">api key</div>
                <div className="flex items-center">
                    <input
                        type={tokenVisible ? "text" : "password"}
                        className="h-full bg-gray-900 text-sm rounded-lg block w-1/2 p-2.5 dark:focus:border-blue-500"
                        value={token}
                        readOnly
                        disabled
                    />
                    <div
                        className="hover:bg-dark cursor-pointer h-full rounded-lg p-2.5 flex items-center justify-center ml-2"
                        onClick={() => copyToClipboard(token)}
                    >
                        <span className="material-symbols-outlined">
                            content_copy
                        </span>
                    </div>
                    <div
                        className="hover:bg-dark cursor-pointer h-full rounded-lg p-2.5 flex items-center justify-center ml-2"
                        onClick={toggleTokenVisibility}
                    >
                        <span className="material-symbols-outlined">
                            {tokenVisible ? "visibility_off" : "visibility"}
                        </span>
                    </div>
                </div>
            </div>
            <div className="border border-dark my-4" />
            <div className="flex">
                <div className="w-1/2">
                    <div className="text-lg mb-4">Parameters</div>
                    {params.map(p => {
                        return (
                            <div key={p.name} className="py-2 border-t border-dark">
                                <div>
                                    <div className="flex items-center flex-wrap">
                                        <span>
                                            {p.name}&emsp;
                                        </span>
                                        <span className={p.required ? "text-red-error opacity-80" : "opacity-50"}>
                                            {p.required ? "required" : "optional"}&emsp;&emsp;
                                        </span>
                                        <span className="opacity-50">
                                            {p.type}
                                        </span>
                                    </div>
                                    {
                                        p.default !== null &&
                                        (
                                            <div className="opacity-50 text-sm mt-0.5">
                                                {`defaults to ${p.default}`}
                                            </div>
                                        )
                                    }
                                    <div className="opacity-75 mt-2 text-sm">
                                        {p.description}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="ml-10 w-1/2">
                    <div>
                        <div className="pt-2 opacity-70 mb-4">
                            <span className="bg-purple text-center p-1 text-whitish">
                                {method}
                            </span>
                            <span>
                                &emsp;
                            </span>
                            <span>
                                {url}
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="text-lg mb-4">Headers</div>
                        <div className="pt-2 border-t border-dark opacity-60">
                            <span>
                                Authorization
                                &emsp;
                            </span>
                            <span>
                                {`Bearer {api_key}`}
                            </span>
                        </div>
                        <div className="pt-2 opacity-60">
                            <span>
                                Content-Type
                                &emsp;
                            </span>
                            <span>
                                application/json
                            </span>
                        </div>
                        <div className="pt-2 opacity-60">
                            <span>
                                Accept
                                &emsp;
                            </span>
                            <span>
                                application/json
                            </span>
                        </div>
                    </div>
                    <div>
                        <div className="text-lg my-4">Request</div>
                        <div>
                            <SyntaxHighlighter
                                language="json"
                                style={nord}
                                customStyle={{
                                    fontSize: "small",
                                    borderRadius: 4,
                                    marginTop: 5,
                                    backgroundColor: "#252d32"
                                }}
                            >
                                {codeToString(body)}
                            </SyntaxHighlighter>
                        </div>
                    </div>
                    <div>
                        <div className="text-lg my-4">Response</div>
                        <div className="pt-2 border-t border-dark opacity-60">
                            Successful calls return with a 200 OK status and response body below
                        </div>
                        <div>
                            <Markdown
                                children={payload200Markdown}
                                components={{
                                    code({ node, inline, className, children, ...props }) {
                                        const match = /language-(\w+)/.exec(className || '')
                                        return !inline && match ? (
                                            <SyntaxHighlighter
                                                {...props}
                                                children={String(children).replace(/\n$/, '')}
                                                style={nord}
                                                customStyle={{
                                                    fontSize: "small",
                                                    borderRadius: 4,
                                                    marginTop: 5,
                                                    backgroundColor: "#252d32"
                                                }}
                                                language={match[1]}
                                                PreTag="div"
                                            />
                                        ) : (
                                            <code {...props} className={className}>
                                                {children}
                                            </code>
                                        )
                                    }
                                }}
                            />
                        </div>
                        <div className="pt-2 border-t border-dark opacity-60">
                            Errored calls return with response body below
                        </div>
                        <div>
                            <Markdown
                                children={payloadErrorMarkdown}
                                components={{
                                    code({ node, inline, className, children, ...props }) {
                                        const match = /language-(\w+)/.exec(className || '')
                                        return !inline && match ? (
                                            <SyntaxHighlighter
                                                {...props}
                                                children={String(children).replace(/\n$/, '')}
                                                style={nord}
                                                customStyle={{
                                                    fontSize: "small",
                                                    borderRadius: 4,
                                                    marginTop: 5,
                                                    backgroundColor: "#252d32"
                                                }}
                                                language={match[1]}
                                                PreTag="div"
                                            />
                                        ) : (
                                            <code {...props} className={className}>
                                                {children}
                                            </code>
                                        )
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
