const config = {
    serverURL: getServerUrl(process.env.REACT_APP_ENV)
}

function getServerUrl(env: string | undefined) {
    const localhost = "http://localhost:8080"
    if (!env) {
        return localhost
    }
    const serverUrlsByEnv: { [env: string]: string } = {
        development: localhost,
        testing: "https://routeopt-test-nocneccbda-uk.a.run.app",
        staging: "https://routeopt-stage-nocneccbda-uk.a.run.app",
        production: "https://routeopt-prod-nocneccbda-uk.a.run.app"
    }
    const serverUrl = serverUrlsByEnv[env]
    if (!serverUrl) {
        return localhost
    }
    return serverUrl
}

export default config
