import { useEffect, useState, useRef } from "react"
import { DateTime } from "luxon"
import { auth } from "fb"

// stores
import { useUserStore } from "stores/global"
import { useOptStore } from "stores/opt"
import { roEmptyState } from "stores/opt"

import {
    onfleetGetTeams,
    onfleetGetHubs,
    onfleetGetWorkers,
    onfleetGetTasks,
} from "calls/onfleet"

import OptimizationPanel from "./components/optimization_panel/OptimizationPanel"
import Sidebar from "./components/Sidebar"
import ApiDoc from "shared/components/ApiDoc"

import {
    apiDocReqParams,
    apiDocExampleReqBody,
    apiUrl,
    apiMethod
} from "./ApiSpec"

import { useDeepCompareEffect } from "hooks"

import type { Message } from "types"
import {
    OnfAllTeamsResponseData,
    OnfAllWorkersResponseData,
    OnfHubsResponseData,
    OnfTasksListResponseData
} from "@/../../../types/services/onfleet"
import type {
    DbOrg,
    DbRoHiddenOption
} from "@/../../../types/lib/db"
import type { MouseEvent } from "react"

type RouteOptimizationProps = {
    setGlobalMessage: (msg: Message) => void,
    org: DbOrg,
    token: string,
}

export default function RouteOptimization({
    setGlobalMessage,
    org,
    token
}: RouteOptimizationProps) {
    const user = useUserStore(state => state.user)
    const initialRender = useRef(true)

    const roStore = useOptStore(state => state.ro)
    const roState = roStore.state
    const roActions = roStore.actions

    const activeFetches = roState.fetch.teams
        || roState.fetch.workers
        || roState.fetch.tasks
        || roState.fetch.hubs


    const [teams, setTeams] = useState<OnfAllTeamsResponseData | null>([])
    const [loadingTeams, setLoadingTeams] = useState(false)

    const [tasks, setTasks] = useState<OnfTasksListResponseData>({})

    const [loadingTasks, setLoadingTasks] = useState(false)

    const [hubs, setHubs] = useState<OnfHubsResponseData>({})
    const [loadingHubs, setLoadingHubs] = useState(false)

    const [workers, setWorkers] = useState<OnfAllWorkersResponseData>({})
    const [loadingWorkers, setLoadingWorkers] = useState(false)

    const [globalUnassignedTaskIds, setGlobalUnassignedTaskIds] = useState<string[]>([])

    const initDateWindow = (timezone?: string) => {
        const date = timezone
            ? DateTime.now().setZone(timezone)
            : DateTime.now()
        return date.toFormat("y-LL-dd")
    }
    const initialDateWindow = initDateWindow(user?.timezone)
    const [taskDateWindow, setTaskDateWindow] = useState<string>(initialDateWindow)

    const [taskIdsSelected, setTaskIdsSelected] = useState<string[]>([])
    const [activePanel, setActivePanel] = useState("")

    const [previousTaskIdSelected, setPreviousTaskIdSelected] = useState<string | null>(null)

    const [loading, setLoading] = useState(true)

    const [apiViewSelected, setApiViewSelected] = useState(false)

    useEffect(() => {
        async function load() {
            setLoading(true)

            try {
                const currentUser = auth.currentUser
                if (!currentUser) {
                    throw new Error(`user isn't authenticated`)
                }

                const idToken = await currentUser.getIdToken(true)
                const orgId = org.id

                const teamSetter = (teams: OnfAllTeamsResponseData) => {
                    setTeams(teams)
                    setLoadingTeams(false)
                }
                if (initialRender.current || (activeFetches && roState.fetch.teams)) {
                    setLoadingTeams(true)
                    setTeams([])
                    onfleetGetTeams(idToken, orgId, teamSetter)
                }

                const taskSetter = (tasks: OnfTasksListResponseData) => {
                    setTasks(tasks)
                    setLoadingTasks(false)
                }
                if (initialRender.current || (activeFetches && roState.fetch.tasks)) {
                    setLoadingTasks(true)
                    onfleetGetTasks(idToken, orgId, taskDateWindow, taskSetter)
                }

                const workerSetter = (workers: OnfAllWorkersResponseData) => {
                    setWorkers(workers)
                    setLoadingWorkers(false)
                }
                if (initialRender.current || (activeFetches && roState.fetch.workers)) {
                    setLoadingWorkers(true)
                    setWorkers({})
                    onfleetGetWorkers(idToken, orgId, workerSetter)
                }

                const hubSetter = (hubs: OnfHubsResponseData) => {
                    setHubs(hubs)
                    setLoadingHubs(false)
                }
                if (initialRender.current || (activeFetches && roState.fetch.hubs)) {
                    onfleetGetHubs(idToken, orgId, hubSetter)
                }
            } catch (err: any) {
                console.error(err.message)
            } finally {
                initialRender.current = false
                roActions.triggerFetches({
                    tasks: false,
                    teams: false,
                    workers: false,
                    hubs: false
                })
                setLoading(false)
            }
        }
        if ((user && initialRender.current) || (user && activeFetches)) {
            load()
        }
    }, [user, activeFetches])

    useEffect(() => {
        roActions.triggerFetches({
            tasks: true
        })
    }, [taskDateWindow])

    useDeepCompareEffect(() => {
        if (teams !== null && user !== null) {
            const initForDate = user.timezone
                ? DateTime.now().setZone(user.timezone).toFormat("yyyy-MM-dd")
                : DateTime.now().toFormat("yyyy-MM-dd")

            const intitialWorkersSelectedPerTeam: {
                [teamId: string]: {
                    workers_selected: string[],
                    workers_in_team: string[]
                }
            } = {}
            for (const team of teams) {
                intitialWorkersSelectedPerTeam[team.id] = {
                    workers_selected: [],
                    workers_in_team: team.workers
                }
            }
            roActions.initRoState({
                ...roEmptyState,
                for_date: initForDate,
                timezone: user?.timezone ?? "America/Los_Angeles",
                workers_selected_per_team: intitialWorkersSelectedPerTeam,
                initialize_state: false,
                ...(org && {
                    optimization_settings: org.services.route_optimization.optimization_settings,
                    simple_quantity_management: org.services.route_optimization.simple_quantity_management
                })
            })
        }
    }, [roState.initialize_state, teams, user])


    useEffect(() => {
        if (tasks) {
            const globalUnassignedTaskIds = Object.entries(tasks)
                .filter(([_, task]) => {
                    return task.container?.type === "ORGANIZATION"
                })
                .map(([taskId]) => {
                    return taskId
                })
            setGlobalUnassignedTaskIds(globalUnassignedTaskIds)
        }
    }, [tasks])

    const handleOptimizationPanelToggle = () => {
        setActivePanel(state => {
            if (state !== "optimization_panel") {
                return "optimization_panel"
            }
            return ""
        })
        setGlobalMessage({ type: "info", body: "" })
    }

    const handleTaskSelectionToggle = (
        taskId: string,
        e: MouseEvent<HTMLDivElement>,
        type: "team" | "global_unassigned",
        teamId: string = ""
    ) => {
        const shiftKeyPressed = e.nativeEvent.shiftKey

        const taskIdSet = new Set<string>(taskIdsSelected)

        if (taskIdSet.has(taskId)) {
            taskIdSet.delete(taskId)
            setTaskIdsSelected([...taskIdSet])
            setPreviousTaskIdSelected(taskId)
            return
        }
        taskIdSet.add(taskId)
        setTaskIdsSelected([...taskIdSet])
        setPreviousTaskIdSelected(taskId)

        function findTasksBetweenInclusive(taskIds: string[] = [], shiftSelectedTask: string) {
            const prevSelectionIndex = taskIds.indexOf(previousTaskIdSelected ?? "null")
            const currSelectionIndex = taskIds.indexOf(shiftSelectedTask)
            if (prevSelectionIndex === -1 || currSelectionIndex === -1) {
                return []
            }
            if (prevSelectionIndex < currSelectionIndex) {
                return taskIds.slice(prevSelectionIndex, currSelectionIndex + 1)
            } else if (currSelectionIndex < prevSelectionIndex) {
                return taskIds.slice(currSelectionIndex, prevSelectionIndex + 1)
            } else {
                return taskIds.slice(prevSelectionIndex, currSelectionIndex + 1)
            }
        }

        if (shiftKeyPressed) {
            switch (type) {
                case "team": {
                    const matchedTeam = teams?.find(t => t.id === teamId)
                    if (!matchedTeam) {
                        return
                    }
                    const matchedTeamTypelessRef = matchedTeam as any
                    const tasksToToggle = findTasksBetweenInclusive(matchedTeamTypelessRef.tasks, taskId)
                    const taskIdSet = new Set(taskIdsSelected)
                    for (const taskId of tasksToToggle) {
                        if (taskId === previousTaskIdSelected) {
                            continue
                        }
                        if (taskIdSet.has(taskId)) {
                            taskIdSet.delete(taskId)
                            continue
                        }
                        taskIdSet.add(taskId)
                    }
                    setTaskIdsSelected([...taskIdSet])
                    setPreviousTaskIdSelected(taskId)
                    return
                }
                case "global_unassigned": {
                    const tasksToToggle = findTasksBetweenInclusive(globalUnassignedTaskIds, taskId)
                    const taskIdSet = new Set(taskIdsSelected)
                    for (const taskId of tasksToToggle) {
                        if (taskId === previousTaskIdSelected) {
                            continue
                        }
                        if (taskIdSet.has(taskId)) {
                            taskIdSet.delete(taskId)
                            continue
                        }
                        taskIdSet.add(taskId)
                    }
                    setTaskIdsSelected([...taskIdSet])
                    setPreviousTaskIdSelected(taskId)
                    return
                }
                default: {
                    return
                }
            }
        }

    }

    if (apiViewSelected) {
        return (
            <div className="flex flex-col text-gray-400 h-5/6 p-5 overflow-auto">
                <div className="w-full flex justify-start text-sm px-10 mt-4">
                    <div
                        className={`border border-dark w-60 mr-4 p-2 rounded text-center cursor-pointer hover:border-gray-500 ${apiViewSelected ? "" : "border-gray-500"}`}
                        onClick={() => {
                            setApiViewSelected(false)
                        }}
                    >
                        Run Route Optimization
                    </div>
                </div>
                <div className="mx-12">
                    <ApiDoc
                        token={token}
                        params={apiDocReqParams}
                        body={apiDocExampleReqBody}
                        method={apiMethod}
                        url={apiUrl}
                    />
                </div>
            </div>
        )
    }

    const roHidden: DbRoHiddenOption[] = org.services.route_optimization.hidden

    return (
        <div className="flex p-5 justify-between text-gray-400 h-5/6">
            <Sidebar
                loadingTasks={loadingTasks}
                loadingTeams={loadingTeams}
                loadingWorkers={loadingWorkers}
                teams={teams}
                tasks={tasks}
                workers={workers}
                taskIdsSelected={taskIdsSelected}
                handleTaskSelectionToggle={handleTaskSelectionToggle}
                globalUnassignedTaskIds={globalUnassignedTaskIds}
                taskDateWindow={taskDateWindow}
                setTaskDateWindow={setTaskDateWindow}
                setTaskIdsSelected={setTaskIdsSelected}
            />
            {
                (activePanel === "optimization_panel" && teams && workers && tasks)
                    ? (
                        <OptimizationPanel
                            teams={teams}
                            workers={workers}
                            hubs={hubs}
                            handleOptimizationPanelToggle={handleOptimizationPanelToggle}
                            setGlobalMessage={setGlobalMessage}
                            tasks={tasks}
                            taskIdsSelected={taskIdsSelected}
                            setTaskIdsSelected={setTaskIdsSelected}
                            org={org}
                        />
                    )
                    : (
                        <div className="flex flex-col items-center w-2/3 text-sm sm:text-base border-l-2 border-dark ml-8 px-2">
                            <div className="w-full flex justify-end mb-4 text-sm min-h-8">
                                {
                                    !roHidden.includes("ro_api") &&
                                    (
                                        <div
                                            className={`border border-dark w-32 mr-4 p-2 rounded flex  items-center cursor-pointer hover:border-gray-500 ${apiViewSelected ? "border-gray-500" : ""}`}
                                            onClick={() => {
                                                setApiViewSelected(true)
                                            }}
                                        >
                                            <span className="material-symbols-outlined mr-2">
                                                code
                                            </span>
                                            API
                                        </div>
                                    )
                                }
                            </div>
                            <div className="min-h-full rounded-md px-5 py-2 flex w-full flex-col items-center">
                                <h2 className="flex justify-center">Usage Guide</h2>
                                <div className="flex justify-center mt-4 text-gray-500 opacity-90 w-3/4">
                                    {`only tasks created within the last ${org.services.route_optimization.lookback_days} days will be visible.`}
                                    <br />
                                    {`if you require more than ${org.services.route_optimization.lookback_days} days of task history please contact us.`}
                                </div>
                                <div className="mt-2 flex justify-center pt-4">
                                    <ol className="list-decimal opacity-80">
                                        <li>Select tasks</li>
                                        <li>Click the purple button</li>
                                        <li>Configure your optimization settings</li>
                                        <li>Run optimization and view / accept results</li>
                                    </ol>
                                </div>
                                <div className="mt-8 flex justify-center w-3/4">
                                    <button
                                        className="w-full bg-purple hover:bg-purple-light text-sm sm:text-base p-2 rounded opacity-75 text-whitish outline-none"
                                        onClick={handleOptimizationPanelToggle}
                                    >
                                        {
                                            taskIdsSelected.length > 1
                                                ? `Optimize (${taskIdsSelected.length} tasks)`
                                                : "Optimize tasks"
                                        }
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
            }
        </div>
    )
}
